import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();
import FeatherIcon from "feather-icons-react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [categoria, setCategoria] = useState("novidades");
  const [imagens, setImagens] = useState([]);
  const [imagem, setImagem] = useState(null);
  const fileInputRef = useRef(null); // Adicionando a referência

  const toggle = () => {
    setTitulo("");
    setTexto("");
    setCategoria("novidades");
    setImagens([]);

    setModal(!modal);
  };

  const handleImageUpload = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("image", imagem);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const response = await apiClient.post(
      "/mural-aviso/image/upload",
      data,
      headers
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      const newImage = {
        path: response.dados,
        principal: imagens.length === 0, // Set the first image as principal
      };
      setImagens([...imagens, newImage]);
      setImagem(null); // Clear the image input field
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Esvaziar o campo de upload de imagem
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const handlePrincipalChange = (index) => {
    const updatedImages = imagens.map((img, i) => ({
      ...img,
      principal: i === index,
    }));
    setImagens(updatedImages);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = imagens.filter((_, i) => i !== index);
    if (imagens[index].principal && updatedImages.length > 0) {
      updatedImages[0].principal = true; // Set another image as principal if the deleted one was principal
    }
    setImagens(updatedImages);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      titulo,
      texto,
      categoria,
      imagens,
    };

    const response = await apiClient.post("/mural-aviso", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle()}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader className="modal-title" toggle={() => toggle()}>
        Novo Anúncio
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Row>
          <Col md="6" className="mb-3">
            <Label for="titulo">Título</Label>
            <Input
              type="text"
              id="titulo"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
          </Col>
          <Col md="6" className="mb-3">
            <Label for="categoria">Categoria</Label>
            <Input
              type="select"
              id="categoria"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            >
              <option value="novidades">Mural de Anúncios</option>
              <option value="novos_conteudos">Novos Conteúdos</option>
              <option value="jogos">Jogos do Dia</option>
            </Input>
          </Col>
          <Col md="12" className="mb-3">
            <Label for="texto">Texto</Label>
            <div className="ck-editor-reverse">
              <CKEditor
                editor={ClassicEditor}
                config={{
                  language: "pt-br",
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
                onChange={(event, editor) => {
                  setTexto(editor.getData());
                }}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <div className="mb-1">
            <b>Upload de Imagem</b>
          </div>
          <Col md="9" className="mb-3">
            <Input
              type="file"
              id="imageUpload"
              onChange={(e) => setImagem(e.target.files[0])}
              ref={fileInputRef} // Adicionando a referência ao input
            />
          </Col>
          <Col md="3" className="mb-3">
            <Button
              color="primary"
              onClick={() => handleImageUpload()}
              disabled={!imagem || loading}
            >
              {loading ? <Spinner size="sm" /> : null}
              Upload
            </Button>
          </Col>
        </Row>

        <Table className="mt-3">
          <thead>
            <tr>
              <th>Path</th>
              <th>Principal</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {imagens.map((img, index) => (
              <tr key={index}>
                <td>{img.path}</td>
                <td>
                  <Input
                    type="radio"
                    name="principal"
                    checked={img.principal}
                    onChange={() => handlePrincipalChange(index)}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-link btn-sm text-danger"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <FeatherIcon icon="trash-2" width="16" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-secondary fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Fechar
          </Link>
          <button
            className="btn btn-secondary"
            onClick={() => criar()}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : null}
            Criar
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
