import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Spinner } from "reactstrap";

const Widgets = ({ arvore, loading }) => {
  const [widgetsData, setWidgetsData] = useState([
    {
      label: "Ativos",
      badgeClass: "success",
      badge: "mdi mdi-arrow-up-bold",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: 0,
      decimals: 0,
      linkTitle: "Ver todos",
      link: "/clientes?status=Ativos",
      bgcolor: "success",
      icon: "mdi mdi-account-circle",
    },
    {
      label: "Esgotados",
      badgeClass: "warning",
      badge: "mdi mdi-arrow-up-bold",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: 0,
      decimals: 0,
      linkTitle: "Ver todos",
      link: "/clientes?status=Esgotados",
      bgcolor: "warning",
      icon: "mdi mdi-account-circle",
    },
    {
      label: "Bloqueados",
      badgeClass: "danger",
      badge: "mdi mdi-arrow-down-bold",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: 0,
      decimals: 0,
      linkTitle: "Ver todos",
      link: "/clientes?status=Bloqueados",
      bgcolor: "danger",
      icon: "mdi mdi-account-off-outline",
    },
    {
      label: "Testes",
      badgeClass: "info",
      badge: "mdi mdi-arrow-up-bold",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: 0,
      decimals: 0,
      linkTitle: "Ver todos",
      link: "/testes",
      bgcolor: "info",
      icon: "mdi mdi-alpha-t-circle-outline",
    },
  ]);

  useEffect(() => {
    let widgetsDataTemp = [...widgetsData];
    widgetsDataTemp[0].counter = arvore?.clientes?.ativos || 0;
    widgetsDataTemp[1].counter = arvore?.clientes?.esgotados || 0;
    widgetsDataTemp[2].counter = arvore?.clientes?.bloqueados || 0;
    widgetsDataTemp[3].counter = arvore?.testes || 0;
    setWidgetsData(widgetsDataTemp);
  }, [arvore]);

  return (
    <React.Fragment>
      {widgetsData.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target={item.counter}>
                      {loading ? (
                        <Spinner size="sm" />
                      ) : (
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={4}
                        />
                      )}
                    </span>
                  </h4>
                  <Link to={item.link} className="text-decoration-underline">
                    {item.linkTitle}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded fs-3 bg-" + item.bgcolor + "-subtle"
                    }
                  >
                    <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
