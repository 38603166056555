import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalApagarMassa = ({
  modalApagarMassa,
  setModalApagarMassa,
  setRefresh,
  selecionados,
  tratarStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const apagar = async () => {
    setLoading(true);

    const data = {
      clientes: selecionados.map((cliente) => cliente.id),
    };

    const response = await apiClient.delete("/clientes/massa/apagar", {}, data);
    if (response.sucesso) {
      toast.success(response.mensagem || "Clientes apagados com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalApagarMassa(!modalApagarMassa);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalApagarMassa}
      toggle={() => {
        setModalApagarMassa(!modalApagarMassa);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalApagarMassa(!modalApagarMassa);
        }}
      >
        Apagar {selecionados.length} Clientes
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  Os seguintes clientes serão{" "}
                  <b className={`text-danger`}>apagados</b>:
                </p>
                <ul className="list-group">
                  {selecionados.map((cliente, index) => {
                    const clienteStatus = tratarStatus(cliente);
                    return (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        {cliente.username}
                        <span
                          className={`badge bg-${
                            clienteStatus === "Ativo"
                              ? "success"
                              : clienteStatus === "Esgotado"
                              ? "danger"
                              : clienteStatus === "Esgotando"
                              ? "primary"
                              : clienteStatus === "Bloqueado"
                              ? "info"
                              : "dark"
                          }`}
                        >
                          {clienteStatus}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <small className="text-muted">
                  Os clientes irão para a lixeira do sistema.
                </small>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-danger fw-medium`}
              onClick={() => setModalApagarMassa(!modalApagarMassa)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-danger`}
              onClick={() => apagar()}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagarMassa;
