import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarMesesMassa = ({
  modalAdicionarMesesMassa,
  setModalAdicionarMesesMassa,
  setRefresh,
  selecionados,
}) => {
  const [loading, setLoading] = useState(false);
  const [mesesAdicionar, setMesesAdicionar] = useState({});

  useEffect(() => {
    // Initialize mesesAdicionar with 1 for each selected client
    const initialMeses = selecionados.reduce((acc, cliente) => {
      acc[cliente.id] = "1";
      return acc;
    }, {});
    setMesesAdicionar(initialMeses);
  }, [selecionados]);

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const adicionarLista = (id, meses) => {
    setMesesAdicionar({
      ...mesesAdicionar,
      [id]: meses,
    });
  };

  const calcularPreco = (cliente, meses) => {
    if(!cliente) return 0;
    const conexoes = cliente?.painel_tipo === 0 ? 1 : cliente?.conexoes;
    const precoOperacao =
      parseInt(meses || "0") *
      conexoes *
      cliente?.painel?.preco_credito;
      
    return precoOperacao;
  };

  const calcularTotalCreditos = () => {
    return selecionados.reduce((total, cliente) => {
      const meses = mesesAdicionar[cliente.id] || "1";
      return total + calcularPreco(cliente, meses);
    }, 0);
  };

  const renovar = async () => {
    setLoading(true);

    const mesesTratados = Object.fromEntries(
      Object.entries(mesesAdicionar).map(([id, meses]) => [
        id,
        meses === "" ? 0 : parseInt(meses),
      ])
    );

    const data = { 
      meses: mesesTratados,
      clientes: selecionados.map(cliente => cliente.id)
    };

    const response = await apiClient.put(
      "/clientes/massa/renovar",
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem || "Meses adicionados com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMesesAdicionar({});
      setModalAdicionarMesesMassa(!modalAdicionarMesesMassa);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalAdicionarMesesMassa}
      toggle={() => {
        setModalAdicionarMesesMassa(!modalAdicionarMesesMassa);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarMesesMassa(!modalAdicionarMesesMassa);
        }}
      >
        Renovar Clientes em Massa
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {!loading && (
          <div className="mt-4">
            {selecionados.length > 0 &&
              selecionados.map((cliente, index) => (
                <Row className="mt-3" key={index}>
                  <Col md="6">
                    <Label htmlFor="meses" className="form-label">
                      {cliente.username}
                      <small className="text-muted d-block">
                        Expiração: {converterData(cliente.expiracao)}
                      </small>
                    </Label>
                  </Col>
                  <Col md="6">
                    <Input
                      type="number"
                      className="form-control"
                      id="meses"
                      value={mesesAdicionar[cliente.id] || "1"}
                      onChange={(e) =>
                        adicionarLista(
                          cliente.id,
                          e.target.value.replace(/\D/g, "")
                        )
                      }
                      maxLength={8}
                    />
                    <small className="text-muted">
                      Custo: {calcularPreco(cliente, mesesAdicionar[cliente.id]).toFixed(2)} crédito(s)
                    </small>
                  </Col>
                </Row>
              ))}
            <Row className="mt-4">
              <Col md="12">
                <div className="alert alert-info text-center">
                  <strong>Total de Créditos:</strong> {calcularTotalCreditos().toFixed(2)} crédito(s)
                </div>
              </Col>
            </Row>
            <div className="hstack gap-2 justify-content-center mt-3">
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() =>
                  setModalAdicionarMesesMassa(!modalAdicionarMesesMassa)
                }
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link
                to="#"
                className="btn btn-success"
                onClick={() => renovar()}
              >
                Renovar
              </Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarMesesMassa;
