import React, { useState, useEffect, useCallback } from "react";
import { Card, CardHeader, CardBody, Spinner, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import FeatherIcon from "feather-icons-react";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ClientesDataChart = ({ arvore, loading }) => {
  const [chartData, setchartData] = useState([0, 0, 0]);

  var dountchartUserDeviceColors = getChartColorsArray(
    '["--vz-success", "--vz-danger", "--vz-info"]'
  );
  const options = {
    labels: ["Ativos", "Esgotados", "Bloqueados"],
    chart: {
      type: "donut",
      height: 219,
    },
    plotOptions: {
      pie: {
        size: 100,
        donut: {
          size: "76%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + " clientes";
        },
      },
      tickAmount: 4,
      min: 0,
    },
    colors: dountchartUserDeviceColors,
  };

  useEffect(() => {
    arvore?.clientes &&
      setchartData([
        arvore.clientes?.ativos,
        arvore.clientes?.esgotados,
        arvore.clientes?.bloqueados,
      ]);
  }, [arvore, loading]);
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Clientes</h4>
        </CardHeader>
        {loading ? (
          <Row className="justify-content-center mt-2 mb-2">
            <Spinner color="info" />
          </Row>
        ) : (
          <div className="card-body pb-0">
            <div id="sales-forecast-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                dir="ltr"
                options={options}
                series={chartData}
                type="donut"
                height="219"
                className="apex-charts"
              />
            </div>
            <div className="table-responsive mt-3">
              <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                <tbody className="border-0">
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-success me-2"></i>
                        Ativos
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[0]}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-danger me-2"></i>
                        Esgotados
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[1]}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-info me-2"></i>
                        Bloqueados
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[2]}
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "31.19px" }}></tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

const RevendasDataChart = ({ arvore, loading }) => {
  const [chartData, setchartData] = useState([0, 0, 0]);

  var dountchartUserDeviceColors = getChartColorsArray(
    '["--vz-success", "--vz-danger", "--vz-info"]'
  );

  const options = {
    labels: ["Ativos", "Esgotados", "Bloqueados"],
    chart: {
      type: "donut",
      height: 219,
    },
    plotOptions: {
      pie: {
        size: 100,
        donut: {
          size: "76%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + " revendas";
        },
      },
      tickAmount: 4,
      min: 0,
    },
    colors: dountchartUserDeviceColors,
  };

  useEffect(() => {
    arvore?.revendedores &&
      setchartData([
        arvore.revendedores?.ativos,
        arvore.revendedores?.esgotados,
        arvore.revendedores?.bloqueados,
      ]);
  }, [arvore, loading]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Revendas</h4>
        </CardHeader>
        {loading ? (
          <Row className="justify-content-center mt-2 mb-2">
            <Spinner color="info" />
          </Row>
        ) : (
          <div className="card-body pb-0">
            <div id="sales-forecast-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                dir="ltr"
                options={options}
                series={chartData}
                type="donut"
                height="219"
                className="apex-charts"
              />
            </div>
            <div className="table-responsive mt-3">
              <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                <tbody className="border-0">
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-success me-2"></i>
                        Ativos
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[0]}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-danger me-2"></i>
                        Esgotados
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[1]}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 className="text-truncate fs-14 fs-medium mb-0">
                        <i className="ri-stop-fill align-middle fs-18 text-info me-2"></i>
                        Bloqueados
                      </h4>
                    </td>
                    <td className="text-end">
                      <p className="text-muted mb-0">
                        <FeatherIcon icon="users" className="me-2 icon-sm" />
                        {chartData[2]}
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: "31.19px" }}></tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

const ClientesRevevendaGrowChart = ({ arvore, loading }) => {
  const [totalClientes, setTotalClientes] = useState(0);
  const [totalRevendas, setTotalRevendas] = useState(0);
  const [categories, setCategories] = useState([
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ]);
  const [series, setSeries] = useState([
    {
      name: "Clientes Ativos",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Revendas Ativos",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [maxValue, setMaxValue] = useState(50);

  var revenueExpensesChartsColors = getChartColorsArray(
    '["--vz-success", "--vz-secondary"]'
  );

  var options = {
    chart: {
      height: 290,
      type: "area",
      toolbar: "false",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
      },
      tickAmount: 5,
      min: 0,
      max: maxValue,
    },
    colors: revenueExpensesChartsColors,
    fill: {
      opacity: 0.06,
      colors: revenueExpensesChartsColors,
      type: "solid",
    },
  };

  const fetchData = useCallback(async (arvore) => {
    const params = {
      clientes_ativos: arvore?.clientes?.ativos || 0,
      revendedores_ativos: arvore?.revendedores?.ativos || 0,
    };

    const response = await apiClient.get(
      `/dashboard/get-grafico/${params.clientes_ativos}/${params.revendedores_ativos}`
    );
    if (response.sucesso) {
      const graficoData = response.dados;
      setSeries([
        {
          name: "Clientes Ativos",
          data: graficoData.clientes_ativos,
        },
        {
          name: "Revendas Ativos",
          data: graficoData.revendedores_ativos,
        },
      ]);
      let valorMaximo = Math.max(
        ...graficoData.clientes_ativos,
        ...graficoData.revendedores_ativos
      );
      setMaxValue(Math.ceil(valorMaximo / 10) * 10);
      setCategories(graficoData.meses);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const executeFetchData = async (arvore) => {
      await fetchData(arvore);
    };
    if (arvore?.clientes) {
      setTotalClientes(arvore.clientes?.ativos);
      setTotalRevendas(arvore.revendedores?.ativos);
      executeFetchData(arvore);
    }
  }, [arvore, loading]);
  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Crescimento do Painel</h4>
        </CardHeader>
        {loading ? (
          <Row className="justify-content-center mt-2 mb-2">
            <Spinner color="info" />
          </Row>
        ) : (
          <CardBody className="px-0">
            <ul className="list-inline main-chart text-center mb-0">
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h4 className="text-success">
                  {totalClientes}
                  <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                    Clientes
                  </span>
                </h4>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h4 className="text-info">
                  {totalRevendas}
                  <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                    Revendas
                  </span>
                </h4>
              </li>
            </ul>

            <div id="revenue-expenses-charts" dir="ltr">
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="area"
                height="290"
                className="apex-charts"
              />
            </div>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

export { ClientesDataChart, RevendasDataChart, ClientesRevevendaGrowChart };
