import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { useQuill } from "react-quilljs";

const apiClient = new APIClient();

const ModalCompose = ({
  modal,
  toggle,
  loading,
  setLoading,
  setRefresh
}) => {
  const { quillRef, quill } = useQuill();
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [editorVisible, setEditorVisible] = useState(false);

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        setEditorVisible(true);
      }, 100);
    } else {
      setEditorVisible(false);
    }
  }, [modal]);

  const enviarAviso = async () => {
    setLoading(true);

    const data = {
      id_destinatario: 0,
      assunto,
      mensagem: quillRef.current.firstChild.innerHTML,
    };

    const response = await apiClient.post("/avisos", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setAssunto("");
      setMensagem("");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
      style={{ display: 'block' }}
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Novo Aviso de Painel
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-3">
            <Input
              type="text"
              className="form-control"
              placeholder="Assunto"
              value={assunto}
              onChange={(e) => {
                setAssunto(e.target.value);
              }}
            />
          </div>
          {editorVisible && (
            <div className="snow-editor" style={{ minHeight: '300px' }}>
              <div ref={quillRef} className="ql-container ql-snow" />
            </div>
          )}
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            toggle();
          }}
        >
          Descartar
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            enviarAviso();
          }}
          disabled={loading}
        >
          Enviar
        </button>
      </div>
    </Modal>
  );
};

export default ModalCompose;
