import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalTesteRapido = ({ modalTesteRapido, setModalTesteRapido }) => {
  const [loading, setLoading] = useState(false);
  const [horas, setHoras] = useState("2");
  const [painelTipo, setPainelTipo] = useState("0");
  const [planos, setPlanos] = useState([]);
  const [planosTeste, setPlanosTeste] = useState([]);
  const [nome, setNome] = useState("");

  const criar = async () => {
    setLoading(true);

    const response = await apiClient.post("/testes/teste-rapido", {
      horas,
      painel_tipo: parseInt(painelTipo),
      planos: planosTeste.map((plano) => parseInt(plano)),
      nome,
    });

    if (response.sucesso) {
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
    localStorage.removeItem("lastUpdated");

    setHoras("2");
    setPainelTipo("0");
    setPlanosTeste([]);

    setModalTesteRapido(!modalTesteRapido);
    setTimeout(() => {
      window.location.href = "/testes";
    }, 1500);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/planos");
    if (response.sucesso) {
      setPlanos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (modalTesteRapido) {
      fetchData();
    }
  }, [modalTesteRapido, fetchData]);
  return (
    <Modal
      isOpen={modalTesteRapido}
      toggle={() => {
        setModalTesteRapido(!modalTesteRapido);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalTesteRapido(!modalTesteRapido);
        }}
      >
        Novo Teste
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome
                </Label>
                <Input
                  id="nome"
                  placeholder="Nome do teste é opcional..."
                  className="form-control"
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
                <small className="text-muted">
                  Deixe em branco para gerar um nome aleatório.
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="horas" className="form-label">
                  Horas
                </Label>
                <Input
                  id="horas"
                  className="form-control"
                  type="select"
                  value={horas}
                  onChange={(e) => setHoras(e.target.value)}
                >
                  <option value="2">2</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="painelTipo" className="form-label">
                  Painel Tipo
                </Label>
                <Input
                  type="select"
                  id="painelTipo"
                  className="form-select"
                  value={painelTipo}
                  onChange={(e) => {
                    setPainelTipo(e.target.value);
                    setPlanosTeste([]);
                  }}
                >
                  <option value="0">P2P</option>
                  <option value="1">IPTV</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label htmlFor="planos" className="form-label">
                Planos
              </Label>
              <div>
                {planos.map((plano, index) => {
                  if (plano.painel_tipo === parseInt(painelTipo)) {
                    return parseInt(painelTipo) === 1 ? (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosTeste.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosTeste([...planosTeste, plano.id]);
                            } else {
                              setPlanosTeste(
                                planosTeste.filter((item) => item !== plano.id)
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ) : (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoTeste"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosTeste.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosTeste([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => setModalTesteRapido(!modalTesteRapido)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar Teste Rápido
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalTesteRapido;
