import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Input,
} from "reactstrap";

import MuralAnuncios from "./MuralAnuncios";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Widget from "./Widgets";
import Section from "./Section";
import UsersExpirando from "./UsersExpirando";
import UsersExpirados from "./UsersExpirados";
import UltimosTestes from "./UltimosTestes";
import {
  ClientesDataChart,
  RevendasDataChart,
  ClientesRevevendaGrowChart,
} from "./ClientesRevendasCharts";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  limparMensagem,
} from "../../Components/FunctionsRepository";
import img1 from "../../assets/images/small/img-1.jpg";
import { Link } from "react-router-dom";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const DashboardEcommerce = () => {
  document.title = "Dashboard | Dark Store";
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [arvore, setArvore] = useState(null);
  const [clientesTables, setClientesTables] = useState({
    expirando: [],
    expirados: [],
    testes: [],
  });

  const [urlTestesP2p, setUrlTestesP2p] = useState("");
  const [urlTestesIptv, setUrlTestesIptv] = useState("");

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (fetchedData) {
      return;
    }
    setFetchedData(true);
    const response = await apiClient.get("/dashboard/get-arvore");
    if (response.sucesso) {
      setArvore(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    const clientesResponse = await apiClient.get(
      "/dashboard/relatorio-clientes"
    );
    if (clientesResponse.sucesso) {
      setClientesTables(clientesResponse.dados);
    } else {
      console.error(clientesResponse.mensagem);
      if (!clientesResponse.mensagem.includes(" 401")) {
        if (clientesResponse.mensagem !== "")
          toast.error(clientesResponse.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !clientesResponse.sucesso &&
      (!clientesResponse.responseCode || clientesResponse.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
    setRefresh(false);
  }, []);

  useEffect(() => {
    const executeFecthData = async () => {
      await fetchData();
      const urlTestesBase = "https://clientes.hzx.digital/testes/";
      const userDados = apiClient.getLoggedinUser();

      //fazer uma string base64 com o seguinte formato: usuario_logado_id:0
      const base64P2p = btoa(`${userDados?.data?.id}:0:6`);
      const base64Iptv = btoa(`${userDados?.data?.id}:1:6`);

      setUrlTestesP2p(`${urlTestesBase}${base64P2p}`);
      setUrlTestesIptv(`${urlTestesBase}${base64Iptv}`);
    };

    if (!arvore) {
      executeFecthData();
    }
  }, [refresh]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Principal" pageTitle="Painel" />
          <Section />
          <Row>
            <Col lg={9}>
              <Row>
                <Col>
                  <div className="h-100">
                    <Row>
                      <Widget arvore={arvore} loading={loading} />
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={12}>
                        <Card className="card-animate">
                          <CardHeader>
                            <h4>URL de Testes Automáticos P2P</h4>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col lg={12}>
                                <div className="input-group">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="urlP2p"
                                    value={urlTestesP2p || "Carregando..."}
                                    onChange={() => {}}
                                    disabled
                                  />
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        urlTestesP2p
                                      );
                                      toast.success("URL copiada com sucesso.");
                                    }}
                                    disabled={!urlTestesP2p}
                                  >
                                    Copiar
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6} sm={12} md={12}>
                        <Card className="card-animate">
                          <CardHeader>
                            <h4>URL de Testes Automáticos IPTV</h4>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col lg={12}>
                                <div className="input-group">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="urlIptv"
                                    value={urlTestesIptv || "Carregando..."}
                                    onChange={() => {}}
                                    disabled
                                  />
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        urlTestesIptv
                                      );
                                      toast.success("URL copiada com sucesso.");
                                    }}
                                    disabled={!urlTestesIptv}
                                  >
                                    Copiar
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <UsersExpirando
                  clientes={clientesTables.expirando || []}
                  loading={loading}
                  converterData={converterData}
                  setRefresh={setRefresh}
                />
                <UsersExpirados
                  clientes={clientesTables.expirados || []}
                  loading={loading}
                  converterData={converterData}
                  setRefresh={setRefresh}
                />
                <UltimosTestes
                  testes={clientesTables.testes || []}
                  loading={loading}
                  converterData={converterData}
                  setRefresh={setRefresh}
                />
              </Row>
            </Col>

            <MuralAnuncios />
          </Row>
          <Row>
            <Col lg={3} sm={6} md={6}>
              <ClientesDataChart arvore={arvore} loading={loading} />
            </Col>
            <Col lg={3} sm={6} md={6}>
              <RevendasDataChart arvore={arvore} loading={loading} />
            </Col>
            <Col lg={6} sm={12} md={12}>
              <ClientesRevevendaGrowChart arvore={arvore} loading={loading} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
