import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";

const ModalDetalhes = ({ isOpen, toggle, conteudo, tipo }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderEpisodios = () => {
    if (tipo !== "serie" || !conteudo?.episodes) return null;

    // Group episodes by season and sort them
    const episodiosPorTemporada = conteudo?.episodes.reduce((acc, episodio) => {
      if (!acc[episodio.temporada]) {
        acc[episodio.temporada] = [];
      }
      acc[episodio.temporada].push(episodio);
      return acc;
    }, {});

    // Sort episodes within each season
    Object.keys(episodiosPorTemporada).forEach((temporada) => {
      episodiosPorTemporada[temporada].sort((a, b) => a.episodio - b.episodio);
    });

    // Sort seasons in ascending order
    const temporadasOrdenadas = Object.keys(episodiosPorTemporada).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );

    return (
      <div>
        <Nav tabs>
          {temporadasOrdenadas?.map((temporada, index) => (
            <NavItem key={temporada}>
              <NavLink
                className={classnames({ active: activeTab === `${index + 1}` })}
                onClick={() => toggleTab(`${index + 1}`)}
              >
                Temporada {temporada}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {temporadasOrdenadas?.map((temporada, index) => (
            <TabPane tabId={`${index + 1}`} key={temporada}>
              <div className="row g-3 mt-2">
                {episodiosPorTemporada[temporada]?.map((episodio) => (
                  <div
                    key={episodio.id}
                    className="col-xxl-3 col-lg-4 col-md-6"
                  >
                    <div className="border rounded p-2 text-center">
                      <img
                        src={episodio.cover}
                        alt={`Episódio ${episodio.episodio}`}
                        className="img-fluid rounded mb-2"
                        style={{ maxHeight: "150px", objectFit: "cover" }}
                      />
                      <h6>Episódio {episodio.episodio}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>{conteudo?.titulo}</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column align-items-center">
          <a
            href={
              conteudo?.cover
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                conteudo?.cover
              }
              alt={conteudo?.titulo}
              className="img-fluid rounded mb-3"
              style={{
                maxHeight: "300px",
                objectFit: "cover",
                width: "100%",
              }}
            />
          </a>
          <div className="text-center mb-3">
            <h4>{conteudo?.titulo}</h4>
            <p className="text-muted">
              {conteudo?.categoria?.nome || "Sem categoria"}
            </p>
          </div>
          <div className="text-center mb-3">
            <p>{conteudo?.descricao || "Sem descrição disponível."}</p>
          </div>

          {tipo === "serie" && renderEpisodios()}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDetalhes;
