import React, { useState, useEffect, useCallback } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap";

import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import UnlimitedUser from "./UnlimitedUser";

const apiClient = new APIClient();

const UserConfig = () => {
  const [userDados, setUserDados] = useState({});
  const [nome, setNome] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [telegram, setTelegram] = useState("");

  const [clientesAtivos, setClientesAtivos] = useState(0);
  const [externoLogin, setExternoLogin] = useState(false);
  const [externoSenha, setExternoSenha] = useState("");
  const [idExterno, setIdExterno] = useState(0);
  const [painelTipo, setPainelTipo] = useState(0);
  const [loading, setLoading] = useState(false);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  const getData = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
    setNome(dados.data.nome);
    setEmail(dados.data.email || "");
    setWhatsapp(dados.data.whatsapp || "");
    setTelegram(dados.data.telegram || "");
    setUsername(dados.data.username);
    setClientesAtivos(dados.data.clientes_ativos);
    setPassword("");
  };

  const editar = async () => {
    setLoading(true);

    const data = {
      nome,
      email,
      whatsapp,
      telegram,
      password: password,
      password_confirmation: passwordConfirm,
    };

    const response = await apiClient.put(`/revendedores/${userDados.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      await refreshUser();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData();
    };
    fetchData();
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <b>Editar seu Usuário</b>
          </h4>
        </CardHeader>
        <CardBody>
          <h5 className="card-title mb-4">Dados Cadastrais</h5>
          <small className="text-muted">
            As informações de contato aparecerão apenas para seus clientes diretos. Nenhum outro usuário do sistema terá acesso a essas informações.
          </small>
          <Row>
            <Col lg="12" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Nome</Label>
                <Input
                  id="nome"
                  placeholder="Digite seu nome"
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Usuário</Label>
                <Input
                  id="username"
                  type="text"
                  value={username}
                  onChange={() => {}}
                  disabled
                />
                <small className="text-muted">
                  Nome de usuário não pode ser alterado.
                </small>
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Email</Label>
                <Input
                  id="email"
                  placeholder="Digite seu email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={255}
                />
                <small className="text-muted">
                  Para poder solicitar reset de senha, um email válido é
                  necessário.
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">WhatsApp</Label>
                <Input
                  id="whatsapp"
                  placeholder="Digite seu número de WhatsApp"
                  type="text"
                  value={whatsapp}
                  onChange={(e) =>
                    setWhatsapp(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={255}
                />
                <small className="text-muted">
                  O formato deve ser somente números, incluindo o DDI (55 para
                  Brasil), o DDD e o número do telefone.
                </small>
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Username do Telegram</Label>
                <Input
                  id="telegram"
                  placeholder="Digite seu username do Telegram"
                  type="text"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>

          <h5 className="card-title mb-4 mt-5">Alteração de Senha</h5>
          <Row>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Senha</Label>
                <Input
                  id="password"
                  placeholder="Digite apenas se deseja alterar a senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={30}
                />
              </div>
            </Col>
            <Col lg="6" className="mt-3">
              <div className="form-group">
                <Label className="form-label">Confirmação de Senha</Label>
                <Input
                  id="passwordConfirm"
                  placeholder="Digite a senha novamente"
                  type="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  maxLength={30}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {" "}
            <div className="text-center mt-4">
              <Button
                type="button"
                color="danger"
                onClick={() => editar()}
                disabled={loading}
              >
                Atualizar
              </Button>
            </div>
          </Row>
        </CardBody>
      </Card>

      {clientesAtivos >= 100 && (
        <UnlimitedUser userDados={userDados} refreshUser={refreshUser} />
      )}
    </>
  );
};

export default UserConfig;
