import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarCreditosMassa = ({
  modalAdicionarCreditosMassa,
  setModalAdicionarCreditosMassa,
  setRefresh,
  selecionados,
}) => {
  const [loading, setLoading] = useState(false);
  const [revendedores, setRevendedores] = useState([]);
  const [creditosAdicionar, setCreditosAdicionar] = useState([]);

  const adicionarLista = (id, creditos) => {
    setCreditosAdicionar({
      ...creditosAdicionar,
      [id]: creditos,
    });
  };

  const adicionar = async () => {
    setLoading(true);

    const creditosTratados = Object.fromEntries(
      Object.entries(creditosAdicionar).map(([id, creditos]) => [
        id,
        creditos === "" ? 0 : parseInt(creditos),
      ])
    );

    const data = { creditos: creditosTratados };

    const response = await apiClient.post(
      "/revendedores/adicionar-creditos-massa/",
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem || "Créditos adicionados com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setCreditosAdicionar([]);
      setModalAdicionarCreditosMassa(!modalAdicionarCreditosMassa);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (selecionados) => {
    setLoading(true);

    const data = { selecionados };
    const response = await apiClient.post("/revendedores/get-massa", data);
    if (response.sucesso) {
      setRevendedores(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (modalAdicionarCreditosMassa && selecionados.length > 0) {
      fetchData(selecionados);
    }
  }, [modalAdicionarCreditosMassa, fetchData]);
  return (
    <Modal
      isOpen={modalAdicionarCreditosMassa}
      toggle={() => {
        setModalAdicionarCreditosMassa(!modalAdicionarCreditosMassa);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarCreditosMassa(!modalAdicionarCreditosMassa);
        }}
      >
        Adicionar Créditos em Massa
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {!loading && (
          <div className="mt-4">
            {revendedores.length > 0 &&
              revendedores.map((revendedor, index) => (
                <Row className="mt-3" key={index}>
                  <Col md="6">
                    <Label htmlFor="creditos" className="form-label">
                      {revendedor.username} (Atual: {revendedor.creditos})
                    </Label>
                  </Col>
                  <Col md="6">
                    <Input
                      type="number"
                      className="form-control"
                      id="creditos"
                      value={creditosAdicionar[revendedor.id] || ""}
                      onChange={(e) =>
                        adicionarLista(
                          revendedor.id,
                          e.target.value.replace(/\D/g, "")
                        )
                      }
                      maxLength={8}
                    />
                  </Col>
                </Row>
              ))}
            <div className="hstack gap-2 justify-content-center mt-5">
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() =>
                  setModalAdicionarCreditosMassa(!modalAdicionarCreditosMassa)
                }
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link
                to="#"
                className="btn btn-success"
                onClick={() => adicionar()}
              >
                Adicionar
              </Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarCreditosMassa;
