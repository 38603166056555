import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Input} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  toggle,
  loading,
  setLoading,
  setRefresh,
  aviso,
}) => {
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [resetLeitura, setResetLeitura] = useState(true);

  const toggleModal = () => {
    setAssunto("");
    setMensagem("");
    setResetLeitura(true);
    toggle();
  };

  const editarAviso = async (aviso) => {
    setLoading(true);

    const data = {
      reset: resetLeitura,
      assunto,
      mensagem,
    };

    const response = await apiClient.put(`/avisos/${aviso.id}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggleModal();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setAssunto(aviso.assunto);
      setMensagem(aviso.mensagem);
    };
    if (modal) fetch();
  }, [modal, aviso]);

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggleModal}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggleModal}>
        Editar Aviso de Painel
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-3">
            <Input
              type="text"
              className="form-control"
              placeholder="Assunto"
              value={assunto}
              onChange={(e) => {
                setAssunto(e.target.value);
              }}
            />
          </div>
          {/* checkbox de "resetar?" */}
          <div className="mb-3">
            <Input
              type="checkbox"
              id="resetLeitura"
              checked={resetLeitura}
              onChange={(e) => {
                setResetLeitura(e.target.checked);
              }}
            />
            <label htmlFor="resetLeitura" className="ms-2">
              Resetar confirmação de leitura?
            </label>
          </div>
          <div className="ck-editor-reverse">
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: "pt-br",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
              }}
              data={mensagem}
              onChange={(event, editor) => {
                setMensagem(editor.getData());
              }}
            />
          </div>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-success"
          onClick={() => {
            toggleModal();
          }}
        >
          Fechar
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            editarAviso(aviso);
          }}
          disabled={loading}
        >
          Editar
        </button>
      </div>
    </Modal>
  );
};

export default ModalEditar;
