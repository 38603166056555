import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  generateRandomString,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();
import FeatherIcon from "feather-icons-react";

const ModalNovo = ({ modalNovo, setModalNovo, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [telefone, setTelefone] = useState("");
  const [meses, setMeses] = useState("1");
  const [painelTipo, setPainelTipo] = useState("0");
  const [conexoes, setConexoes] = useState("1");
  const [planos, setPlanos] = useState([]);
  const [planosCliente, setPlanosCliente] = useState([]);
  const [observacao, setObservacao] = useState("");

  const copyWhatsAppText = async () => {
    const whatsappTextarea = document.getElementById("whatsapp");
    if (whatsappTextarea) {
      try {
        await navigator.clipboard.writeText(whatsappTextarea.value);
        toast.success("Texto copiado para a área de transferência!");
      } catch (err) {
        toast.error("Falha ao copiar texto.");
        console.error(err);
      }
    }
  };

  const formatEmail = (value) => {
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      nome,
      username,
      password: senha,
      email,
      telefone,
      meses,
      painel_tipo: parseInt(painelTipo),
      planos: planosCliente.map((plano) => parseInt(plano)),
      conexoes: parseInt(conexoes),
      observacao,
    };

    const response = await apiClient.post("/clientes", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      setNome("");
      setUsername("");
      setEmail("");
      setSenha("");
      setTelefone("");
      setMeses("1");
      setPainelTipo("0");
      setConexoes("1");
      setPlanosCliente([]);
      setObservacao("");

      setModalNovo(!modalNovo);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/planos");
    if (response.sucesso) {
      setPlanos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setUsername(generateRandomString());
    setSenha(generateRandomString());

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchData();
    };
    if (modalNovo) {
      fetch();
    }
  }, [modalNovo, fetchData]);
  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        setModalNovo(!modalNovo);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalNovo(!modalNovo);
        }}
      >
        Novo Cliente
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="username" className="form-label">
                  Usuário <span className="text-danger">*</span>
                </Label>
                <Input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <small className="text-muted">
                  De 8 a 15 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e.target.value)}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <Input
                  id="telefone"
                  className="form-control"
                  type="text"
                  placeholder="Telefone"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="senha" className="form-label">
                  Senha
                </Label>
                <Input
                  id="senha"
                  className="form-control"
                  type="text"
                  placeholder="Digite a Senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
                <small className="text-muted">
                  Mínimo de 8 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="meses" className="form-label">
                  Meses <span className="text-danger">*</span>
                </Label>
                <Input
                  id="meses"
                  className="form-control"
                  type="text"
                  placeholder="Meses"
                  value={meses}
                  onChange={(e) => setMeses(e.target.value.replace(/\D/g, ""))}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="painelTipo" className="form-label">
                  Painel Tipo <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  id="painelTipo"
                  className="form-select"
                  value={painelTipo}
                  onChange={(e) => {
                    setPainelTipo(e.target.value);
                    setPlanosCliente([]);
                  }}
                >
                  <option value="0">P2P</option>
                  <option value="1">IPTV</option>
                </Input>
              </div>
            </Col>
            {painelTipo === "1" && (
              <Col md="6">
                <div>
                  <Label htmlFor="conexoes" className="form-label">
                    Conexões <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="conexoes"
                    className="form-control"
                    type="text"
                    placeholder="Conexões"
                    value={conexoes}
                    onChange={(e) =>
                      setConexoes(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label htmlFor="planos" className="form-label">
                Planos <span className="text-danger">*</span>
              </Label>
              <div>
                {planos.map((plano, index) => {
                  if (plano.painel_tipo === parseInt(painelTipo)) {
                    return parseInt(painelTipo) === 1 ? (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([...planosCliente, plano.id]);
                            } else {
                              setPlanosCliente(
                                planosCliente.filter(
                                  (item) => item !== plano.id
                                )
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ) : (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoCliente"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="observacao" className="form-label mb-3">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="whatsapp" className="form-label">
                  Texto para WhatsApp
                </Label>{" "}
                <Link
                  to="#"
                  className="btn btn-link link-warning fw-medium"
                  onClick={() => copyWhatsAppText()}
                  disabled={loading}
                >
                  <FeatherIcon
                    icon="copy"
                    className="cursor-pointer"
                    size={14}
                  />{" "}
                  Copiar
                </Link>
                <Input
                  id="whatsapp"
                  className="form-control"
                  type="textarea"
                  value={`Olá! Obrigado por escolher nossos serviços. Aqui estão suas informações de acesso:\n\n**Usuário**: ${username}\n**Senha**: ${senha}\n**Tipo de acesso**: ${
                    painelTipo === "0" ? "P2P" : "IPTV"
                  }\n\nPara acessar o seu painel de cliente, vá em https://clientes.hzx.digital e use seu usuário e senha acima. Lá você encontrará os aplicativos ideais para assistir o conteúdo da HZ e um webplayer exclusivo para nossos conteúdos.\n\nAproveite uma nova maneira de assistir TV! 🎬📺`}
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => setModalNovo(!modalNovo)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
