import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "./SearchOption";
import ModalDetalhes from "./ModalDetalhes";
import CustomPagination from "./CustomPagination";
import "./Conteudos.css";

import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Conteudos = () => {
  document.title = "Conteúdos | Dark Store";

  const [conteudos, setConteudos] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [conteudoTipo, setConteudoTipo] = useState("filme");
  const [totalDeConteudos, setTotalDeConteudos] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("titulo");
  const [orderAsc, setOrderAsc] = useState(true);
  const [loading, setLoading] = useState(true);

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedConteudo, setSelectedConteudo] = useState(null);

  const toggleModal = (conteudo = null) => {
    setSelectedConteudo(conteudo);
    setModalOpen(!modalOpen);
  };

  const arrowNavToggle = (tab) => {
    if (conteudoTipo !== tab) {
      setConteudoTipo(tab);
      setPaginaAtual(1);
      setLoading(true);
    }
  };

  const fetchConteudos = useCallback(
    async (
      itensPorPagina,
      paginaAtual,
      termoBusca,
      orderBy,
      orderAsc,
      conteudoTipo
    ) => {
      setLoading(true);
      const data = {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
      };

      try {
        const response = await apiClient.post(
          `/stream/busca/${conteudoTipo}`,
          data
        );
        if (response.sucesso) {
          const dados =
            response.dados[
              conteudoTipo === "filme"
                ? "filmes"
                : conteudoTipo === "serie"
                ? "series"
                : "canais"
            ];
          setConteudos(dados.data);
          setNumeroDePaginas(dados.last_page);
          setTotalDeConteudos(dados.total);
        } else {
          console.error(response.mensagem);
          if (!response.mensagem.includes(" 401"))
            if (response.mensagem !== "") toast.error(response.mensagem);
        }
        if (
          !response.sucesso &&
          (!response.responseCode || response.responseCode === 401)
        ) {
          await checkLastLogin();
        }
      } catch (error) {
        console.error("Erro ao buscar conteúdos:", error);
        toast.error("Erro ao carregar conteúdos");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const fetchData = async (
      itensPorPagina,
      paginaAtual,
      termoBusca,
      orderBy,
      orderAsc,
      conteudoTipo
    ) => {
      await fetchConteudos(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        orderBy,
        orderAsc,
        conteudoTipo
      );
    };
    fetchData(
      itensPorPagina,
      paginaAtual,
      termoBusca,
      orderBy,
      orderAsc,
      conteudoTipo
    );
  }, [
    itensPorPagina,
    paginaAtual,
    termoBusca,
    orderBy,
    orderAsc,
    conteudoTipo,
  ]);

  const renderConteudosMosaico = () => {
    if (loading) {
      return (
        <div className="text-center p-5">
          <Spinner color="primary" className="me-2">
            Carregando...
          </Spinner>
          <span className="fw-medium">Carregando conteúdos...</span>
        </div>
      );
    }

    if (!conteudos?.length) {
      return (
        <div className="text-center p-5">
          <FeatherIcon icon="inbox" size={48} className="text-muted mb-3" />
          <h5 className="text-muted">Nenhum conteúdo encontrado</h5>
        </div>
      );
    }

    return (
      <div className="conteudos-mosaico">
        {conteudos?.map((conteudo) => (
          <div
            key={conteudo.id}
            className="conteudo-poster"
            onClick={() => toggleModal(conteudo)}
          >
            <img
              src={conteudo.cover || "/default-cover.jpg"}
              alt={conteudo.titulo}
              className="poster-image"
            />
            <div className="poster-overlay">
              <span className="poster-title">{conteudo.titulo}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Conteúdos" pageTitle="Catálogo" />

          <div className="content-container">
            <Card className="mb-4">
              <CardBody>
                <Row className="justify-content-between align-items-center">
                  <Col xl={3}>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setItensPorPagina(parseInt(e.target.value));
                        setPaginaAtual(1);
                      }}
                      value={itensPorPagina}
                    >
                      <option>Itens por Página</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </Col>
                  <Col xl={9}>
                    <SearchOption
                      setTermoBusca={setTermoBusca}
                      setPaginaAtual={setPaginaAtual}
                      setLoading={setLoading}
                      termoBusca={termoBusca}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-success bg-light"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: conteudoTipo === "canal",
                  })}
                  onClick={() => arrowNavToggle("canal")}
                >
                  <FeatherIcon icon="tv" size={16} className="me-1" />
                  Canais de TV
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: conteudoTipo === "filme",
                  })}
                  onClick={() => arrowNavToggle("filme")}
                >
                  <FeatherIcon icon="film" size={16} className="me-1" />
                  Filmes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: conteudoTipo === "serie",
                  })}
                  onClick={() => arrowNavToggle("serie")}
                >
                  <FeatherIcon icon="tv" size={16} className="me-1" />
                  Séries
                </NavLink>
              </NavItem>
            </Nav>

            <Card>
              <CardBody className="p-0">
                {renderConteudosMosaico()}
              </CardBody>
            </Card>

            {!loading && conteudos?.length > 0 && (
              <div className="d-flex justify-content-center mt-4">
                <CustomPagination
                  totalPaginas={numeroDePaginas}
                  paginaAtual={paginaAtual}
                  setPaginaAtual={setPaginaAtual}
                />
              </div>
            )}
          </div>

          <ModalDetalhes
            isOpen={modalOpen}
            toggle={toggleModal}
            conteudo={selectedConteudo}
            tipo={conteudoTipo}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Conteudos;
