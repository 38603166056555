import React, { useCallback, useEffect, useState } from "react";
import { Col, Card, CardBody, CardHeader, Row, Spinner } from "reactstrap";

import ModalAnuncio from "./ModalAnuncio";
import {
  checkLastLogin,
  limparMensagem,
} from "../../Components/FunctionsRepository";
import img1 from "../../assets/images/small/img-1.jpg";
import { Link } from "react-router-dom";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const MuralAnuncios = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [anuncios, setAnuncios] = useState([]);
  // New state for modal
  const [modal, setModal] = useState(false);
  const [selectedAnuncio, setSelectedAnuncio] = useState(null);

  // Function to toggle modal and set selected announcement
  const toggleModal = (anuncio = null) => {
    setModal(!modal);
    setSelectedAnuncio(anuncio);
  };
  const fetchAnuncios = useCallback(async () => {
    setLoading(true);
    const anunciosResponse = await apiClient.post("/mural-aviso/busca", {
      itens_pagina: 3,
      pagina: 1,
    });
    if (anunciosResponse.sucesso) {
      const dadosAnuncios = anunciosResponse.dados;
      setAnuncios(dadosAnuncios.data);
    }
    if (
      !anunciosResponse.sucesso &&
      (!anunciosResponse.responseCode || anunciosResponse.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, [setAnuncios]);

  useEffect(() => {
    fetchAnuncios();
  }, [refresh]);

  return (
    <Col lg={3}>
      {loading ? (
        <Row className="justify-content-center">
          <Spinner size="sm" />
        </Row>
      ) : (
        <Row>
          {anuncios.length === 0 ? (
            <Col xxl={12}>
              <Card>
                <Row className="g-0">
                  <Col md={4}>
                    <img
                      className="rounded-start img-fluid h-100 object-fit-cover"
                      src={img1}
                      alt="Card"
                    />
                  </Col>
                  <Col md={8}>
                    <CardHeader>
                      <h5 className="card-title mb-0">Mural de anuncios</h5>
                    </CardHeader>
                    <CardBody>
                      <p className="card-text mb-2">
                        Veja aqui anuncios relevantes para você e seus
                        revendedores e clientes.
                      </p>
                      <p className="card-text">
                        <small className="text-muted">Aguarde</small>
                      </p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : (
            <>
              {anuncios.map((av, index) => (
                <Col xxl={12} lg={12} md={6} sm={12} key={index}>
                  <Card onClick={() => toggleModal(av)}>
                    <img
                      className="rounded-start img-fluid h-100 object-fit-cover"
                      src={av.imagem_principal}
                      alt="Imagem Principal"
                      style={{ maxHeight: "180px" }}
                    />
                    <CardBody>
                      <h4 className="card-title mb-2">{av.titulo}</h4>
                      <p className="card-text mb-0">
                        {limparMensagem(av.texto, 114)}{" "}
                        <Link to="#" className="card-link link-secondary">
                          Leia Mais{" "}
                          <i className="ri-arrow-right-s-line ms-1 align-middle lh-1"></i>
                        </Link>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col xxl={12} lg={12} md={6} sm={12}>
                <Card>
                  <div className="card-footer">
                    <Link to="/mural-anuncios-blog" className="card-link link-secondary">
                      Ver mais Notícias{" "}
                      <i className="ri-arrow-right-s-line ms-1 align-middle lh-1"></i>
                    </Link>
                  </div>
                </Card>
              </Col>
            </>
          )}
        </Row>
      )}

      <ModalAnuncio
        modal={modal}
        toggleModal={toggleModal}
        anuncio={selectedAnuncio}
      />
    </Col>
  );
};

export default MuralAnuncios;
