import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react/";

const apiClient = new APIClient();

const ModalMercadoPago = ({
  modalMercadoPago,
  setModalMercadoPago,
  parentDados,
}) => {
  const [creditos, setCreditos] = useState("");
  const [qrDados, setQrDados] = useState("");
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModalMercadoPago(!modalMercadoPago);
    setQrDados("");
    setCreditos("");
  };

  const comprarCreditos = async () => {
    setLoading(true);

    const dados = {
      quantidade: creditos,
    };

    const response = await apiClient.post(`/mercado-pago/gerar-qr`, dados);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setQrDados(response.dados?.qrcode);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalMercadoPago}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Comprar Créditos
        <br />
        <small className="fs-12 text-muted">via PIX do Mercado Pago</small>
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {qrDados && qrDados !== "" ? (
          <>
            <p className="text-muted">
              Faça o pagamento via PIX e aguarde ele ser confirmado para os
              créditos serem adicionados.
            </p>
            <div className="bg-soft-warning" style={{ textAlign: "center" }}>
              <QRCode
                value={qrDados}
                size={256}
                className="p-3"
                style={{ background: "white", borderRadius: "10px" }}
              />
              <p className="text-warning mt-3">
                Créditos: {creditos} | Preço: R${" "}
                {parentDados?.valor_revenda * creditos}
              </p>
            </div>
            <div className="hstack gap-2 justify-content-center mt-5">
              <Button
                color="warning"
                className="btn btn-soft-warning"
                onClick={() => {
                  navigator.clipboard.writeText(qrDados);
                  toast.success(
                    "QR Code copiado para a área de transferência."
                  );
                }}
              >
                <FeatherIcon icon="copy" size="16" /> PIX Copia e Cola
              </Button>
              <Link
                to="#"
                className="btn btn-link link-warning fw-medium"
                onClick={() => toggle()}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <Row>
              <Col xs={12}>
                <Label for="creditos" className="form-label">
                  Créditos
                </Label>
                <Input
                  type="text"
                  id="creditos"
                  name="creditos"
                  className="form-control"
                  value={creditos}
                  onChange={(e) =>
                    setCreditos(e.target.value.replace(/\D/g, ""))
                  }
                />
                <small className="text-muted">
                  Preço: R${" "}
                  {(
                    (parentDados?.valor_revenda || 0) *
                    parseInt(creditos === "" ? 0 : creditos)
                  ).toFixed(2)}
                </small>
                {parseInt(creditos === "" ? 0 : creditos) < 5 && (
                  <>
                    <br />
                    <small className="text-muted">Mínimo de 5 créditos</small>
                  </>
                )}
              </Col>
            </Row>

            <div className="hstack gap-2 justify-content-end mt-5">
              <Link
                to="#"
                className="btn btn-link link-warning fw-medium"
                onClick={() => toggle()}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Button
                color="warning"
                className="btn btn-warning"
                onClick={() => comprarCreditos()}
                disabled={loading}
              >
                Gerar QR Code
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalMercadoPago;
