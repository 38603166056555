import React from "react";
import FeatherIcon from "feather-icons-react";
import "./CustomPagination.css";

const CustomPagination = ({ totalPaginas, paginaAtual, setPaginaAtual }) => {
  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, paginaAtual - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPaginas, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => setPaginaAtual(1)}
          className={`pagination-button ${paginaAtual === 1 ? "active" : ""}`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(
          <span key="ellipsis1" className="pagination-ellipsis">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setPaginaAtual(i)}
          className={`pagination-button ${paginaAtual === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPaginas) {
      if (endPage < totalPaginas - 1) {
        pages.push(
          <span key="ellipsis2" className="pagination-ellipsis">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPaginas}
          onClick={() => setPaginaAtual(totalPaginas)}
          className={`pagination-button ${
            paginaAtual === totalPaginas ? "active" : ""
          }`}
        >
          {totalPaginas}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="custom-pagination">
      <button
        className="pagination-nav-button"
        onClick={() => setPaginaAtual(Math.max(1, paginaAtual - 1))}
        disabled={paginaAtual === 1}
      >
        <FeatherIcon icon="chevron-left" size={20} />
      </button>

      <div className="pagination-numbers">{renderPageNumbers()}</div>

      <button
        className="pagination-nav-button"
        onClick={() => setPaginaAtual(Math.min(totalPaginas, paginaAtual + 1))}
        disabled={paginaAtual === totalPaginas}
      >
        <FeatherIcon icon="chevron-right" size={20} />
      </button>
    </div>
  );
};

export default CustomPagination;
