import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalConexoes = ({
  modalConexoes,
  setModalConexoes,
  setRefresh,
  cliente,
}) => {
  const [loading, setLoading] = useState(false);
  const [conexoes, setConexoes] = useState("");

  const [preco, setPreco] = useState(0);

  const calcularPreco = useCallback(
    async (cliente, conexoes) => {
      const conn = parseInt(conexoes || "0");
      const precoOperacao =
        (cliente?.conexoes >= conn ? 0 : conn - cliente?.conexoes) *
        cliente?.painel?.preco_credito;
      setPreco(precoOperacao);
    },
    [cliente]
  );

  const atualizar = async (clienteId) => {
    setLoading(true);

    const response = await apiClient.put(`/clientes/${clienteId}/conexoes`, {
      conexoes,
    });
    console.log(response);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setConexoes("");
      setModalConexoes(!modalConexoes);
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const rodar = async () => {
      await calcularPreco(cliente, conexoes);
    };
    if (cliente) {
      rodar();
    }
    if(conexoes === ""){
      setConexoes(cliente?.conexoes.toString());
    }
  }, [cliente, conexoes, calcularPreco]);

  return (
    <Modal
      isOpen={modalConexoes}
      toggle={() => {
        setModalConexoes(!modalConexoes);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalConexoes(!modalConexoes);
        }}
      >
        Alterar Telas de {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="conexoes" className="form-label">
                  Telas:
                </Label>
                <Input
                  type="select"
                  className="form-select"
                  id="conexoes"
                  value={conexoes}
                  onChange={(e) =>
                    setConexoes(e.target.value.replace(/\D/g, ""))
                  }
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </Input>
                <small className="text-muted">
                  <b>Telas Atuais</b>: {cliente?.conexoes}
                  <br />
                  <b>Custo</b>: {preco.toFixed(1)} crédito(s)
                </small>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => setModalConexoes(!modalConexoes)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-info"
              onClick={() => atualizar(cliente?.id)}
              disabled={loading}
            >
              Atualizar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalConexoes;
