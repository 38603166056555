import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalBloquear = ({
  modalBloquear,
  setModalBloquear,
  setRefresh,
  cliente,
}) => {
  const [loading, setLoading] = useState(false);

  const bloquear = async (status, clienteId) => {
    setLoading(true);
    const url = `/clientes/${clienteId}/${
      status === 2 ? "desbloquear" : "bloquear"
    }`;

    const response = await apiClient.put(url, {});
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalBloquear(!modalBloquear);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalBloquear}
      toggle={() => {
        setModalBloquear(!modalBloquear);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalBloquear(!modalBloquear);
        }}
      >
        {cliente?.status == 2 ? "Desbloquear" : "Bloquear"} {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  O cliente atual será{" "}
                  <b
                    className={`text-${
                      cliente?.status == 2 ? "success" : "danger"
                    }`}
                  >
                    {cliente?.status == 2 ? "desbloqueado" : "bloqueado"}{" "}
                  </b>
                  ao confimar esta ação.
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-${
                cliente?.status == 2 ? "info" : "danger"
              } fw-medium`}
              onClick={() => setModalBloquear(!modalBloquear)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-${cliente?.status == 2 ? "info" : "danger"}`}
              onClick={() => bloquear(cliente?.status, cliente?.id)}
              disabled={loading}
            >
              {cliente?.status == 2 ? "Desbloquear" : "Bloquear"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBloquear;
