import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAssunto = ({ modal, toggle, loading, setLoading }) => {
  const [assuntos, setAssuntos] = useState([]);
  const [assunto, setAssunto] = useState("");
  const [refresh, setRefresh] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get(`/assunto-suporte`);
    if (response.sucesso) {
      setAssuntos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setRefresh(false);
    setLoading(false);
  }, []);

  const criar = async () => {
    setLoading(true);

    const data = {
      titulo: assunto,
    };

    const response = await apiClient.post("/assunto-suporte", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setAssunto("");
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const apagar = async (assuntoId) => {
    setLoading(true);

    const response = await apiClient.delete(`/assunto-suporte/${assuntoId}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const executar = async () => {
      await fetchData();
    };
    if (modal) executar();
  }, [modal, refresh]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Gerenciar Assuntos de Suporte
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div>
          <Row className="mt-3">
            <Col md="12">
              <div className="form-group">
                <Label for="assunto"> Cadastrar Assunto</Label>
                <Input
                  type="text"
                  id="assunto"
                  placeholder="Digite o assunto"
                  value={assunto}
                  onChange={(e) => setAssunto(e.target.value)}
                />
              </div>
              <div className="hstack gap-2 justify-content-end mt-2">
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => criar()}
                  disabled={loading}
                >
                  Cadastrar
                </button>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <table className="table table-sm table-borderless">
              <tbody>
                {assuntos.map((item) => (
                  <tr key={item.id} className="border-bottom">
                    <td className="text-warning p-2">{item.titulo}</td>
                    <td className="text-end">
                      <button
                        className="btn btn-link btn-sm text-danger"
                        onClick={() => apagar(item.id)}
                        disabled={loading}
                      >
                        <FeatherIcon icon="trash-2" size="16" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-info fw-medium`}
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAssunto;
