import React, { useState, useCallback, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Badge } from "reactstrap";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalVer = ({
  modalVer,
  setModalVer,
  setRefresh,
  solicitacao,
  solicitacaoDados,
}) => {
  const [loading, setLoading] = useState(false);
  const [userDados, setUserDados] = useState({});

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const aprovar = async (solicitacaoId) => {
    setLoading(true);

    const response = await apiClient.post(
      `/solicitacao/aprovar/${solicitacaoId}`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalVer(!modalVer);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const reprovar = async (solicitacaoId) => {
    setLoading(true);

    const response = await apiClient.post(
      `/solicitacao/reprovar/${solicitacaoId}`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalVer(!modalVer);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalVer}
      toggle={() => {
        setModalVer(!modalVer);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalVer(!modalVer);
        }}
      >
        {userDados?.id === 1 && solicitacao?.aprovado === 0
          ? "Aprovar / Reprovar solicitação"
          : "Ver solicitação"}
      </ModalHeader>
      <ModalBody className="text-left p-4">
        <div className="mt-4">
          <Row>
            <Col md="6">
              <div>
                <strong>Solicitante</strong>
                <p>{solicitacao?.user?.nome}</p>
              </div>
            </Col>
            <Col md="6">
              {solicitacao?.aprovado === 1 ? (
                <Badge color="success">Aprovado</Badge>
              ) : solicitacao?.aprovado === -1 ? (
                <Badge color="danger">Reprovado</Badge>
              ) : (
                <Badge color="secondary">Pendente</Badge>
              )}
            </Col>
            <Col md="12">
              <div>
                <strong>Pedido</strong>
                <p>
                  {solicitacaoDados?.tipo === "mudar_senha"
                    ? `Mudar senha de ${solicitacaoDados?.username}`
                    : solicitacaoDados?.tipo === "deletar_revendedor"
                    ? `Deletar revendedor ${solicitacaoDados?.username}`
                    : solicitacaoDados?.tipo === "bloquear_revendedor"
                    ? `Bloquear revendedor ${solicitacaoDados?.username}`
                    : solicitacaoDados?.tipo === "remover_credito"
                    ? `Remover ${solicitacaoDados?.creditos} Crédito(s) de ${solicitacaoDados?.username}`
                    : "Desconhecido"}
                </p>
              </div>
            </Col>
            <Col md="12">
              <div>
                <strong>Motivo</strong>
                <p>{solicitacao?.motivo}</p>
              </div>
            </Col>
          </Row>
          {userDados?.id === 1 && solicitacao?.aprovado === 0 && (
            <div className="hstack gap-2 justify-content-center mt-5">
              <button
                className={`btn btn-success`}
                onClick={() => aprovar(solicitacao?.id)}
                disabled={loading}
              >
                Aprovar
              </button>
              <button
                className={`btn btn-danger`}
                onClick={() => reprovar(solicitacao?.id)}
                disabled={loading}
              >
                Reprovar
              </button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalVer;
