import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalApagar = ({ modalApagar, setModalApagar, setRefresh, utmDados }) => {
  const [loading, setLoading] = useState(false);

  const apagar = async (utmId) => {
    setLoading(true);

    const response = await apiClient.delete(`/utm/${utmId}`);
    if (response.sucesso) {
      toast.success(response.mensagem || "Apagado com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalApagar(!modalApagar);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalApagar}
      toggle={() => {
        setModalApagar(!modalApagar);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalApagar(!modalApagar);
        }}
      >
        Apagar {utmDados?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  O dispositivo atual será{" "}
                  <b className={`text-danger`}>completamente apagado</b> do
                  painel original.
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-danger fw-medium`}
              onClick={() => setModalApagar(!modalApagar)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-danger`}
              onClick={() => apagar(utmDados.id)}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
