import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalBloquear = ({
  modalBloquear,
  setModalBloquear,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [userDados, setUserDados] = useState({});

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const bloquear = async (revendedor) => {
    setLoading(true);

    const endpoint = `/revendedores/${
      revendedor.status === 1 ? "bloquear" : "desbloquear"
    }/${revendedor.id}`;
    const data = { motivo };

    const response = await apiClient.post(endpoint, data);
    if (response.sucesso) {
      toast.success(response.mensagem || "Revendedor bloqueado com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMotivo("");
      setModalBloquear(!modalBloquear);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalBloquear}
      toggle={() => {
        setModalBloquear(!modalBloquear);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalBloquear(!modalBloquear);
        }}
      >
        {revendedor?.status === 1 ? "Bloquear" : "Desbloquear"}{" "}
        {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          {revendedor?.status === 2 ? (
            <p>
              Ao prosseguir, você <b className="text-danger"> desbloqueará</b> o
              revendedor {revendedor?.nome}. Tem certeza disso?
            </p>
          ) : userDados?.id !== 1 ? (
            <>
              <Row className="mt-3">
                <small className="text-muted">
                  Este formulário envia um pedido para bloquear o revendedor. O
                  pedido será analisado e, se aprovado, o revendedor será
                  bloqueado.
                </small>
              </Row>
              <Row className="mt-3">
                <div>
                  <Label htmlFor="motivo" className="form-label">
                    Motivo
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="motivo"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    maxLength={255}
                  />
                </div>
              </Row>
            </>
          ) : (
            <p>
              Ao prosseguir, você <b className="text-info">bloqueará</b> o
              revendedor {revendedor?.nome}. Tem certeza disso?
            </p>
          )}

          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-${
                revendedor?.status === 1 ? "info" : "danger"
              } fw-medium`}
              onClick={() => setModalBloquear(!modalBloquear)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-${
                revendedor?.status === 1 ? "info" : "danger"
              }`}
              onClick={() => bloquear(revendedor)}
              disabled={loading}
            >
              {revendedor?.status === 1 ? "Bloquear" : "Desbloquear"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBloquear;
