import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "../helpers/api_helper";
const apiClient = new APIClient();

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isPrincipal, setIsPrincipal] = useState(false);
  const [isUsuarios, setIsUsuarios] = useState(false);
  const [isSuporte, setIsSuporte] = useState(false);
  const [isUtilitarios, setIsUtilitarios] = useState(false);
  const [isFinanceiro, setIsFinanceiro] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);
  const [isAdministrativo, setIsAdministrativo] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Principal");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Principal") {
      setIsPrincipal(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Suporte") {
      setIsSuporte(false);
    }
    if (iscurrentState !== "Utilitarios") {
      setIsUtilitarios(false);
    }
    if (iscurrentState !== "Administrativo") {
      setIsAdministrativo(false);
    }
    if (iscurrentState !== "Financeiro") {
      setIsFinanceiro(false);
    }
    if (iscurrentState !== "Marketing") {
      setIsMarketing(false);
    }
    setUserData(apiClient.getLoggedinUser());
  }, [
    history,
    iscurrentState,
    isPrincipal,
    isUsuarios,
    isSuporte,
    isUtilitarios,
    isAdministrativo,
    isFinanceiro,
    isMarketing,
  ]);

  const menuItems = [
    {
      label: "Geral",
      isHeader: true,
    },
    {
      id: "principal",
      label: "Principal",
      icon: "ri-home-line",
      link: "/principal",
      stateVariables: isPrincipal,
      click: function (e) {
        e.preventDefault();
        setIsPrincipal(!isPrincipal);
        setIscurrentState("Principal");
        updateIconSidebar(e);
      },
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUsuarios(!isUsuarios);
        setIscurrentState("Usuarios");
        updateIconSidebar(e);
      },
      stateVariables: isUsuarios,
      subItems: [
        {
          id: "revendedores",
          label: "Revendedores",
          link: "/revendedores",
          parentId: "usuarios",
        },
        {
          id: "clientes",
          label: "Clientes",
          link: "/clientes",
          parentId: "usuarios",
        },
        {
          id: "testes",
          label: "Testes",
          link: "/testes",
          parentId: "usuarios",
        },
        {
          id: "utm-devices",
          label: "UTM Play Devices",
          link: "/utm-devices",
          parentId: "usuarios",
        },
        {
          id: "utm-devices-pro",
          label: "UTM Pro Devices ",
          link: "/utm-devices-pro",
          parentId: "usuarios",
        },
        {
          id: "mag-devices",
          label: "MAG Devices",
          link: "/mag-devices",
          parentId: "usuarios",
        },
      ],
    },
    {
      id: "financeiro",
      label: "Financeiro",
      icon: "ri-money-dollar-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFinanceiro(!isFinanceiro);
        setIscurrentState("Financeiro");
        updateIconSidebar(e);
      },
      stateVariables: isFinanceiro,
      subItems: [
        {
          id: "pedidos-mercado-pago-pix",
          label: "Pedidos Mercado Pago",
          link: "/pedidos-mercado-pago",
          parentId: "financeiro",
        },
      ],
    },
    {
      id: "suporte",
      label: "Suporte",
      icon: "ri-ticket-2-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsSuporte(!isSuporte);
        setIscurrentState("Suporte");
        updateIconSidebar(e);
      },
      stateVariables: isSuporte,
      subItems: [
        {
          id: "tickets-suporte",
          label: "Tickets de Suporte",
          link: "/tickets-suporte",
          parentId: "suporte",
        },
        {
          id: "solicitacoes",
          label: "Solicitações",
          link: "/solicitacoes",
          parentId: "suporte",
        },
      ],
    },
    {
      id: "marketing",
      label: "Marketing",
      icon: "ri-newspaper-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsMarketing(!isMarketing);
        setIscurrentState("Marketing");
        updateIconSidebar(e);
      },
      stateVariables: isMarketing,
      subItems: [{
          id: "conteudos",
          label: "Buscar Conteúdos",
          link: "/conteudos",
          parentId: "marketing",
        },
        {
          id: "mural-novos-conteudos",
          label: "Novos Conteúdos",
          link: "/mural-novos-conteudos",
          parentId: "marketing",
        },
        {
          id: "mural-jogos",
          label: "Jogos do Dia",
          link: "/mural-jogos",
          parentId: "marketing",
        },
        {
          id: "mural-anuncios-blog",
          label: "Mural de Anúncios",
          link: "/mural-anuncios-blog",
          parentId: "marketing",
        },
      ],
    },
    {
      id: "utilitarios",
      label: "Utilitários",
      icon: "ri-tools-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUtilitarios(!isUtilitarios);
        setIscurrentState("Utilitarios");
        updateIconSidebar(e);
      },
      stateVariables: isUtilitarios,
      subItems: [
        {
          id: "ferramentas",
          label: "Ferramentas",
          link: "/ferramentas",
          parentId: "utilitarios",
        },
        {
          id: "clientes-online",
          label: "Clientes Online",
          link: "/clientes-online",
          parentId: "utilitarios",
        },
        {
          id: "downloads",
          label: "Downloads",
          link: "/downloads",
          parentId: "utilitarios",
        },
        {
          id: "informacoes",
          label: "Informações",
          link: "/informacoes",
          parentId: "utilitarios",
        },
        {
          id: "logs",
          label: "Logs",
          link: "/logs",
          parentId: "utilitarios",
        },
      ],
    },
  ];

  const menuAdmin = [
    {
      id: "administrativo",
      label: "Administrativo",
      icon: "ri-settings-3-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsAdministrativo(!isAdministrativo);
        setIscurrentState("Administrativo");
        updateIconSidebar(e);
      },
      stateVariables: isAdministrativo,
      subItems: [
        {
          id: "ferramentas-admin",
          label: "Fierramentas Admin",
          link: "/ferramentas-admin",
          parentId: "administrativo",
        },
        {
          id: "mural-anuncios",
          label: "Mural de Anúncios",
          link: "/mural-anuncios",
          parentId: "administrativo",
        },
        {
          id: "avisos",
          label: "Avisos no Painel",
          link: "/avisos",
          parentId: "administrativo",
        },
      ],
    },
  ];

  const menuAdicional = [
    {
      id: "administrativo",
      label: "Administrativo",
      icon: "ri-settings-3-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsAdministrativo(!isAdministrativo);
        setIscurrentState("Administrativo");
        updateIconSidebar(e);
      },
      stateVariables: isAdministrativo,
      subItems: [
        {
          id: "mural-anuncios",
          label: "Mural de Anúncios",
          link: "/mural-anuncios",
          parentId: "administrativo",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {userData?.data?.id === 1
        ? [...menuItems, ...menuAdmin]
        : userData?.data?.id === 2169799
        ? [...menuItems, ...menuAdicional]
        : menuItems}
    </React.Fragment>
  );
};
export default Navdata;
