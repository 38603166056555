import React from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalApagar = ({
  modal,
  toggle,
  loading,
  setLoading,
  setRefresh,
  aviso,
}) => {
  const apagar = async (aviso) => {
    setLoading(true);

    const response = await apiClient.delete(`/avisos/${aviso.id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Apagar Aviso de Painel #{aviso?.id}
      </ModalHeader>
      <ModalBody>
        <div>
          <h5 className="text-center">
            Deseja realmente apagar o aviso de painel #{aviso?.id}?
          </h5>
          <p className="text-center">Esta ação não poderá ser desfeita e o aviso <b className="text-danger">{aviso?.assunto}</b> será apagado permanentemente.</p>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            toggle();
          }}
        >
          Fechar
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            apagar(aviso);
          }}
          disabled={loading}
        >
          Apagar
        </button>
      </div>
    </Modal>
  );
};

export default ModalApagar;
