import React, { useEffect, useState } from "react";

import { Modal, ModalBody, ModalHeader, Input, Label } from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalResponder = ({
  modal,
  toggle,
  setModal,
  sidebarClose,
  setRefresh,
  loading,
  ticket,
}) => {
  const [userDados, setUserDados] = useState({});
  const [mensagem, setMensagem] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const updateTicket = async (id) => {
    setLoadingData(true);

    const response = await apiClient.put(`/tickets/${id}`, {
      mensagem,
    });
    if (response.sucesso) {
      toast.success(
        response.mensagem || "Ticket de suporte atualizado com sucesso."
      );
      setRefresh(true);
      setMensagem("");
      sidebarClose("email-detail-show");
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingData(false);
  };

  useEffect(() => {
    const executar = async () => {
      const usuario = JSON.parse(localStorage.getItem("authUser"));
      setUserDados(usuario?.data);
    };

    executar();
  }, []);

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Responder Ticket de Suporte
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-3">
            <Label for="destinatario">Destinatário</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Destinatário"
              value={
                userDados.id === ticket?.destinatario?.id
                  ? ticket?.remetente?.nome
                  : ticket?.destinatario?.nome
              }
              onChange={() => {}}
              disabled
            />
          </div>

          <div className="mb-3">
            <Label for="assunto">Assunto</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Assunto"
              value={ticket?.assunto}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className="ck-editor-reverse">
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: "pt-br",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
              }}
              onChange={(event, editor) => {
                setMensagem(editor.getData());
              }}
            />
          </div>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            toggle();
          }}
        >
          Descartar
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            updateTicket(ticket?.id);
          }}
          disabled={loadingData || loading}
        >
          Enviar
        </button>
      </div>
    </Modal>
  );
};

export default ModalResponder;
