import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import SearchOption from "./SearchOption";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const Pedidos = () => {
  document.title = "Pedidos de Atividade | Dark Store";
  const { user_id } = useParams();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [pedidos, setPedidos] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDePedidos, setTotalDePedidos] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const getPreferenceStatus = async (preferenceId) => {
    const response = await apiClient.get(
      `mercado-pago/${preferenceId}/preference`
    );
    if (response.sucesso) {
      console.log(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const fetchPedidos = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      setLoading(true);

      const userData = JSON.parse(localStorage.getItem("authUser"));
      const userId = user_id ? user_id : userData?.data?.id;

      const response = await apiClient.post(`mercado-pago/${userId}/buscar`, {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
      });

      if (response.sucesso) {
        const dados = response.dados;
        console.log(dados);
        setPedidos(dados.data);
        setTotalDePedidos(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }

      setLoading(false);
    },
    []
  );

  const formatarMoeda = (valor) => {
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchPedidos(itensPorPagina, paginaAtual, termoBusca);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pedidos" pageTitle="Utilitários" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                />
              </Col>
            </Row>
          </Card>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Pedidos de Compra Mercado Pago
                  </h4>
                </CardHeader>
                <CardBody>
                  {!loading && !loadTable && pedidos.length === 0 ? null : (
                    <div className="align-items-center mb-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDePedidos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDePedidos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDePedidos}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDePedidos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "10%" }}>
                                Data
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Revendedor
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Master
                              </th>
                              <th scope="col" style={{ width: "40%" }}>
                                Produto
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Status
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {pedidos.length !== 0 &&
                              pedidos.map((pedido, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{converterData(pedido.created_at)}</td>
                                    <td className="text-info">
                                      {pedido.user?.username}
                                    </td>
                                    <td>{pedido.parent_user?.username}</td>
                                    <td className="text-success">
                                      {pedido.creditos} Créditos | R${" "}
                                      {formatarMoeda(pedido.total)}
                                    </td>
                                    <td className="text-warning">
                                      {pedido.status}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-link"
                                        onClick={() =>
                                          getPreferenceStatus(
                                            pedido.preference_code
                                          )
                                        }
                                      >
                                        <FeatherIcon icon="eye" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && pedidos.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum pedido encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDePedidos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDePedidos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDePedidos}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDePedidos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Pedidos;
