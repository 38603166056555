import React, { useState, useEffect, useCallback } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import QRCode from "qrcode.react";

import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";

const apiClient = new APIClient();

const Config2fa = ({ userData }) => {
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [formulario2fa, setFormulario2fa] = useState(false);
  const [codigoVerificacao, setCodigoVerificacao] = useState(null);

  const [data2fa, setData2fa] = useState(null);

  const firstVerify = async () => {
    setLoading(true);
    const data = {
      code: codigoVerificacao,
    };
    const url = `/secrets2fa/first-verify`;
    const response = await apiClient.post(url, data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      setData2fa(response.dados);
      setFormulario2fa(false);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const cadastrar2fa = async () => {
    setLoading(true);
    const url = `/secrets2fa`;
    const response = await apiClient.post(url);

    if (response.sucesso) {
      setData2fa(response.dados?.secret);
      setQrCode(response.dados?.qr_code);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const desativar2fa = async (userDados) => {
    setLoading(true);
    console.log(userDados);
    const url = `/secrets2fa/${userDados?.id}`;
    const response = await apiClient.delete(url);

    if (response.sucesso) {
      setData2fa(null);
      setQrCode(null);
      setFormulario2fa(false);
      toast.success("2FA desativado com sucesso");
      await get2faStatus(userDados);
    } else {
      toast.error(response.mensagem);
      console.error(response);
    }
    setLoading(false);
  };

  const get2faStatus = useCallback(async (avisar=true) => {
    setLoading(true);
    const url = `/secrets2fa`;
    const response = await apiClient.get(url);

    if (response.sucesso) {
      setData2fa(response.dados);
    } else {
      if(avisar) toast.error(response.mensagem);
      console.error(response);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await get2faStatus(false);
    };
    if (!data2fa) fetch();
    console.log(data2fa);
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <b>2o Fator de Autenticação</b>
          </h4>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Spinner />
          ) : (
            <Row>
              <div className="mb-3">
                <h5>Autenticação de 2 Fatores</h5>
              </div>
              {!data2fa?.id ? (
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>Ative a sua Autenticação de 2 Fatores</Col>
                    <Col lg={6}>
                      <Button color="primary" onClick={() => cadastrar2fa()}>
                        Ativar 2FA
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : !data2fa?.secret || data2fa?.secret === "" ? (
                <Col lg={6}>
                  <Row>
                    <Col lg={6}> O seu 2fa está ativo e funcional</Col>
                    <Col lg={6}>
                      <Button
                        disabled={loading}
                        color="danger"
                        onClick={() => desativar2fa(userData)}
                      >
                        Desativar 2FA
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : (!data2fa?.label || data2fa.label === "") && !qrCode ? (
                <Col lg={6}>
                  <Row>
                    <Col lg={6} className="text-danger">
                      Houve um erro ao gerar o QR Code. Tente novamente
                    </Col>
                    <Col lg={6}>
                      <Button
                        disabled={loading}
                        color="danger"
                        onClick={() => cadastrar2fa()}
                      >
                        Ativar 2FA
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : (!data2fa?.label || data2fa.label === "") &&
                qrCode &&
                !formulario2fa ? (
                <>
                  <Col lg={3}></Col>
                  <Col lg={6} className="d-flex flex-column align-items-center">
                    <Label htmlFor="2fa">
                      Leia o código QR abaixo e aperte em continuar
                    </Label>
                    <QRCode value={qrCode} size={256} className="mt-2" />
                    <div className="mt-5">
                      <Button
                        color="primary"
                        onClick={() => setFormulario2fa(true)}
                        disabled={loading}
                      >
                        Continuar
                      </Button>
                    </div>
                  </Col>
                  <Col lg={3}></Col>
                </>
              ) : formulario2fa ? (
                <>
                  <Col lg={3}></Col>
                  <Col lg={6} className="d-flex flex-column align-items-center">
                    <Label htmlFor="2fa">Digite o código de verificação</Label>
                    <Input
                      type="text"
                      className="mt-2 text-center"
                      id="2fa"
                      value={codigoVerificacao}
                      onChange={(e) =>
                        setCodigoVerificacao(e.target.value.replace(/\D/g, ""))
                      }
                      maxLength={6}
                    />
                    <div className="mt-5">
                      <Button
                        disabled={
                          !codigoVerificacao ||
                          codigoVerificacao.length !== 6 ||
                          loading
                        }
                        color="success"
                        onClick={() => firstVerify()}
                      >
                        Enviar Código
                      </Button>
                    </div>
                  </Col>
                  <Col lg={3}></Col>
                </>
              ) : (
                <Col lg={6}>
                  <Row>
                    <Col lg={6}> O seu 2fa está ativo e funcional</Col>
                    <Col lg={6}>
                      <Button
                        disabled={loading}
                        color="danger"
                        onClick={() => desativar2fa(userData)}
                      >
                        Desativar 2FA
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default Config2fa;
