import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import ModalUrlLista from "./ModalUrlLista";

const AdicionarUrlLista = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <Col lg={6} xxl={4}>
      <div className="card card-body" style={{ height: "100%" }}>
        <div className="avatar-sm mx-auto mb-3">
          <div className="avatar-title bg-info-subtle text-info fs-17 rounded p-3">
            <i className="ri-link"></i>
          </div>
        </div>
        <h4 className="card-title text-center">URL para Listas</h4>
        <Row className="justify-content-center" style={{ height: "100%" }}>
          <p className="text-center text-muted">
            Adiciona ou apaga URLs para serem usadas com as listas de IPTV.
          </p>
        </Row>

        <button
          className="btn btn-info"
          onClick={() => toggle()}
          disabled={loading}
        >
          Nova URL
        </button>
      </div>
      <ModalUrlLista
        modal={modal}
        toggle={toggle}
        loading={loading}
        setLoading={setLoading}
      />
    </Col>
  );
};

export default AdicionarUrlLista;
