import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const ModalMercadoPago = ({
  modalMercadoPago,
  setModalMercadoPago,
  qrDados,
}) => {
  const toggle = () => {
    setModalMercadoPago(!modalMercadoPago);
  };

  return (
    <Modal
      isOpen={modalMercadoPago}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Comprar Créditos
        <br />
        <small className="fs-12 text-muted">via Mercado Pago</small>
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <p className="text-muted">
          Faça o pagamento via PIX e aguarde ele ser confirmado para os créditos
          serem adicionados.
        </p>
        <div className="bg-soft-warning" style={{ textAlign: "center" }}>
          <QRCode
            value={qrDados.qrcode}
            size={256}
            className="p-3"
            style={{ background: "white", borderRadius: "10px" }}
          />
          <p className="text-warning mt-3">
            Créditos: {qrDados.creditos} | Preço: R$ {qrDados.valor}
          </p>
        </div>
        <div className="hstack gap-2 justify-content-center mt-5">
          <Button
            color="warning"
            className="btn btn-warning"
            onClick={() => {
              navigator.clipboard.writeText(qrDados.qrcode);
              toast.success("QR Code copiado para a área de transferência.");
            }}
          >
            <FeatherIcon icon="copy" size="16" /> PIX Copia e Cola
          </Button>
          <Link
            to="#"
            className="btn btn-link link-warning fw-medium"
            onClick={() => toggle()}
          >
            <i className="ri-close-line me-1 align-middle"></i> Fechar
          </Link>


          <p className="text-danger">
            Não pague o mesmo QR Code mais de uma vez.
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMercadoPago;
