import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "reactstrap";
import ModalTesteRapido from "../Testes/ModalTesteRapido";
import ModalMercadoPago from "./ModalMercadoPago";
import { APIClient } from "../../helpers/api_helper";

import { toast } from "react-toastify";

const apiClient = new APIClient();

const Section = (props) => {
  const [userData, setUserData] = useState(null);
  const [parentDados, setParentDados] = useState(null);
  const [modalTesteRapido, setModalTesteRapido] = useState(false);
  const [modalMercadoPago, setModalMercadoPago] = useState(false);

  const getUserData = useCallback(async (id) => {
    const response = await apiClient.get(`/mercado-pago/${id}/config`);
    if (response.sucesso) {
      setParentDados(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (
          response.mensagem !== "" &&
          response.mensagem !== "Configuração não encontrada."
        )
          toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const executeFecthData = async () => {
      const userDados = JSON.parse(localStorage.getItem("authUser"));
      setUserData(userDados?.data);
      if (userDados?.data?.parent_id)
        await getUserData(userDados?.data?.parent_id);
    };

    if (!userData) {
      executeFecthData();
    }
  }, [userData, parentDados]);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                Bem-vindo(a) de volta, {userData?.nome || userData?.username}!
              </h4>
              <small className="text-muted">Os números desta página são referentes a toda a sua árvore de revendedores.</small>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-soft-primary"
                      onClick={() => setModalTesteRapido(!modalTesteRapido)}
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Teste Rápido
                    </button>
                  </div>

                  {parentDados &&
                    parentDados.id_cliente &&
                    parentDados.valor_revenda != 0 && (
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-soft-warning"
                          onClick={() => setModalMercadoPago(!modalMercadoPago)}
                        >
                          <i className="ri-wallet-3-line align-middle me-1"></i>{" "}
                          Comprar Créditos
                        </button>
                      </div>
                    )}
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>

      <ModalTesteRapido
        modalTesteRapido={modalTesteRapido}
        setModalTesteRapido={setModalTesteRapido}
      />
      {parentDados && parentDados.public_key !== "" && (
        <ModalMercadoPago
          modalMercadoPago={modalMercadoPago}
          setModalMercadoPago={setModalMercadoPago}
          parentDados={parentDados}
        />
      )}
    </React.Fragment>
  );
};

export default Section;
