import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const MigrarFree = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const fetchMigrar = useCallback(async () => {
    const response = await apiClient.get(`/clientes/migracao-free/status`);
    if (response.sucesso) {
      setStatus(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const ativarDesativar = async () => {
    setLoading(true);
    const response = await apiClient.put(`/clientes/migracao-free/status`);
    if (response.sucesso) {
      setStatus(!status);
      toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchMigrar();
    };
    fetch();
  }, [fetchMigrar]);

  return (
    <Col lg={6} xxl={4}>
      <div className="card card-body" style={{ height: "100%" }}>
        <div className="avatar-sm mx-auto mb-3">
          <div className="avatar-title bg-success-subtle text-success fs-17 rounded p-3">
            <i className="ri-mail-send-line"></i>
          </div>
        </div>
        <h4 className="card-title text-center">Migração Gratuita</h4>
        <Row className="justify-content-center" style={{ height: "100%" }}>
          <p className="text-center text-muted">
            Esta função permite ativar ou desativar a migração gratuita de
            listas de outros painéis para a Horizon.
          </p>

          <p className="text-center">
            O atual status da migração gratuita é:{" "}
            <b className={`text-${status ? "info" : "danger"}`}>
              {status === 1 ? "Ativado" : "Desativado"}
            </b>
          </p>
        </Row>

        <button
          className={`btn btn-${status ? "danger" : "info"}`}
          onClick={() => ativarDesativar()}
          disabled={loading}
        >
          {status ===1 ? "Desativar" : "Ativar"}
        </button>
      </div>
    </Col>
  );
};

export default MigrarFree;
