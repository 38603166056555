import React, { useState } from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const ModalAnuncio = ({ modal, toggleModal, anuncio }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const nextImage = () => {
    if (currentImageIndex < (anuncio?.imagens?.length || 0) - 1) {
      setCurrentImageIndex(prev => prev + 1);
    }
  };

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(prev => prev - 1);
    }
  };

  const handleImageClick = () => {
    setLightboxOpen(true);
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = anuncio.imagens[currentImageIndex].base64;
    link.download = `imagem-${currentImageIndex + 1}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={() => toggleModal()} size="lg">
        <ModalHeader toggle={() => toggleModal()}>{anuncio?.titulo}</ModalHeader>
        <ModalBody>
          {anuncio?.imagens?.length > 0 && (
            <div className="position-relative">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ 
                  maxHeight: "300px",
                  position: "relative",
                  overflow: "hidden"
                }}
              >
                <img
                  src={anuncio.imagens[currentImageIndex].base64}
                  alt={`img-${currentImageIndex}`}
                  className="img-fluid"
                  style={{ 
                    maxHeight: "300px",
                    objectFit: "cover",
                    margin: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleImageClick}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center gap-4 mt-3">
                {currentImageIndex > 0 && (
                  <button
                    className="btn btn-link p-0"
                    onClick={prevImage}
                    style={{ color: '#000' }}
                  >
                    <FeatherIcon icon="chevron-left" size={24} />
                  </button>
                )}
                {currentImageIndex < (anuncio?.imagens?.length || 0) - 1 && (
                  <button
                    className="btn btn-link p-0"
                    onClick={nextImage}
                    style={{ color: '#000' }}
                  >
                    <FeatherIcon icon="chevron-right" size={24} />
                  </button>
                )}
              </div>
            </div>
          )}
          <Row className="mt-3">
            <div 
              dangerouslySetInnerHTML={{ __html: anuncio?.texto }} 
              style={{ fontSize: "1rem" }}
            />
          </Row>
        </ModalBody>
      </Modal>

      {/* Lightbox Modal */}
      <Modal 
        isOpen={lightboxOpen} 
        toggle={() => setLightboxOpen(false)} 
        size="xl"
        style={{ maxWidth: '100vw', margin: '0' }}
      >
        <ModalBody className="p-0">
          <div className="position-relative">
            <button
              className="btn btn-light position-absolute d-flex align-items-center justify-content-center"
              style={{ 
                top: '1rem', 
                right: '1rem', 
                zIndex: 1000,
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                padding: 0
              }}
              onClick={() => setLightboxOpen(false)}
            >
              <FeatherIcon icon="x" size={20} />
            </button>
            <div 
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ 
                minHeight: '100vh',
                background: 'rgba(0,0,0,0.9)',
                padding: '2rem'
              }}
            >
              <img
                src={anuncio?.imagens[currentImageIndex].base64}
                alt={`fullscreen-img-${currentImageIndex}`}
                style={{ 
                  maxWidth: '100%',
                  maxHeight: 'calc(100vh - 100px)',
                  objectFit: 'contain'
                }}
              />
              <button
                className="btn btn-success mt-3"
                onClick={downloadImage}
              >
                <FeatherIcon icon="download" size={16} className="me-2" />
                Download Imagem
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalAnuncio;
