import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  Collapse,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";

const Regras = () => {
  const [borderCol2, setborderCol2] = useState(true);
  const [borderCol3, setborderCol3] = useState(false);
  const [borderCol4, setborderCol4] = useState(false);
  const [borderCol5, setborderCol5] = useState(false);
  const [borderCol6, setborderCol6] = useState(false);
  const [borderCol7, setborderCol7] = useState(false);
  const [borderCol8, setborderCol8] = useState(false);
  const [borderCol9, setborderCol9] = useState(false);
  const [borderCol10, setborderCol10] = useState(false);
  const [borderCol11, setborderCol11] = useState(false);
  const [borderCol12, setborderCol12] = useState(false);

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol3 = () => {
    setborderCol3(!borderCol3);
    setborderCol2(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol4 = () => {
    setborderCol4(!borderCol4);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol5 = () => {
    setborderCol5(!borderCol5);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol6 = () => {
    setborderCol6(!borderCol6);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol7 = () => {
    setborderCol7(!borderCol7);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol8 = () => {
    setborderCol8(!borderCol8);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol9 = () => {
    setborderCol9(!borderCol9);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol10(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol10 = () => {
    setborderCol10(!borderCol10);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol11(false);
    setborderCol12(false);
  };

  const t_borderCol11 = () => {
    setborderCol11(!borderCol11);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol12(false);
  };

  const t_borderCol12 = () => {
    setborderCol12(!borderCol12);
    setborderCol2(false);
    setborderCol3(false);
    setborderCol4(false);
    setborderCol5(false);
    setborderCol6(false);
    setborderCol7(false);
    setborderCol8(false);
    setborderCol9(false);
    setborderCol10(false);
    setborderCol11(false);
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          Regras e Informações Gerais
        </h4>
      </CardHeader>
      <CardBody>
        <div className="live-preview">
          <Accordion
            className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
            id="accordionBordered"
            toggle={() => {}}
            open=""
          >
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample2">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol2,
                  })}
                  type="button"
                  onClick={t_borderCol2}
                  style={{ cursor: "pointer" }}
                >
                  Regras de Divulgação
                </button>
              </h2>

              <Collapse
                isOpen={borderCol2}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse2"
              >
                <div className="accordion-body">
                  <p>
                    <b>
                      SE VOCE TEM SITE, CANAL NO YOUTUBE E ESTÁ FAZENDO
                      PUBLICIDADE NO GOOGLE, SAIA DO SERVIDOR.
                    </b>{" "}
                    SE ENCONTRARMOS VAMOS DELETAR COM TODOS OS CLIENTES.
                  </p>
                  <p>
                    É importante que cada revendedor anuncie suas vendas como
                    sendo um produto próprio, sem vincular MARCA. O Nome HORIZON
                    é apenas para denominar nosso serviço e não para ser
                    divulgado como uma marca em si.
                  </p>
                  <p>
                    Pedimos que não sejam feitos videos ou imagens do PAINEL,
                    APP e publicado com acesso publico.
                  </p>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample3">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol3,
                  })}
                  type="button"
                  onClick={t_borderCol3}
                  style={{ cursor: "pointer" }}
                >
                  Deletei um usuário por engano
                </button>
              </h2>
              <Collapse
                isOpen={borderCol3}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse3"
              >
                <div className="accordion-body text">
                  <b>Não há como recuperar, nem há reembolso.</b> A opção{" "}
                  <b className="text-danger">Deletar</b> exibe uma mensagem
                  antes de concluir a solicitação.
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample4">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol4,
                  })}
                  type="button"
                  onClick={t_borderCol4}
                  style={{ cursor: "pointer" }}
                >
                  Horários de suporte
                </button>
              </h2>
              <Collapse
                isOpen={borderCol4}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse4"
              >
                <div className="accordion-body text">
                  <p>
                    Nosso suporte é de{" "}
                    <b>segunda a sexta de 9h da manhã às 8h da noite</b>.
                    Sábados e domingos só há suporte para problemas do servidor.
                    ANTES de abrir novos tickets, fale com seu fornecedor de
                    créditos.
                  </p>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample5">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol5,
                  })}
                  type="button"
                  onClick={t_borderCol5}
                  style={{ cursor: "pointer" }}
                >
                  Regras e Preço de Revenda
                </button>
              </h2>
              <Collapse
                isOpen={borderCol5}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse5"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      <b>NÃO VENDER ABAIXO DA TABELA.</b> ISSO NÃO SERÁ
                      TOLERADO, TENDO EM VISTA QUE O PREÇO JÁ ESTÁ ADEQUADO E
                      COM ISSO CONSEGUIMOS MANTER A QUALIDADE.
                    </li>
                    <br />
                    <li>
                      <b>NÃO OFERECER PARA OUTROS REVENDAS JÁ DA HORIZON.</b>{" "}
                      Vamos ter bom senso.{" "}
                    </li>
                    <br />
                    <li>
                      <b>PREÇO CLIENTE FINAL.</b> Sugerimos preço minimo de{" "}
                      <b className="text-info">R$ 25,00 IPTV</b> e{" "}
                      <b className="text-info">R$ 30,00 P2P</b>, porém isso pode
                      variar dentro da sua área de atuação. Temos muitos
                      revendedores que trabalham a <b>R$ 40,00 ou R$ 50,00</b> e
                      possuem centenas de clientes.{" "}
                    </li>
                    <br />
                    <li>
                      <b>CRIAÇÃO DE ACESSO:</b> Não fazer cadastro com usuario e
                      senha igual e não criar acesso com menos de 7 caracteres
                      com letras e números. Isso pode deixar o servidor
                      vulnerável a ataques e causará problemas a todos.
                    </li>
                    <br />
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample6">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol6,
                  })}
                  type="button"
                  onClick={t_borderCol6}
                  style={{ cursor: "pointer" }}
                >
                  Painel inativo
                </button>
              </h2>
              <Collapse
                isOpen={borderCol6}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse6"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      Painel zerado por mais de 1 semana será deletado do
                      servidor e os clientes mantidos até o vencimento.
                    </li>
                    <br />
                    <li>
                      Revendas que já estão no servidor há mais de 5 meses e não
                      possuem mais que 5 usuarios ativos, serão excluidos.
                    </li>
                    <br />
                    <li>
                      Não vendemos painel para uso próprio, o preço para revenda
                      não é o mesmo de cliente final.
                    </li>
                    <br />
                    <li>
                      <b>Painel sem créditos</b> o Revenda não tem acesso.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample7">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol7,
                  })}
                  type="button"
                  onClick={t_borderCol7}
                  style={{ cursor: "pointer" }}
                >
                  Deveres Horizon
                </button>
              </h2>
              <Collapse
                isOpen={borderCol7}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse7"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>Garantir um serviço de qualidade.</li>
                    <br />
                    <li>Suporte 24 horas.</li>
                    <br />
                    <li>
                      Pedidos de filmes, séries e canais sempre informar ao
                      master.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample8">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol8,
                  })}
                  type="button"
                  onClick={t_borderCol8}
                  style={{ cursor: "pointer" }}
                >
                  Abertura de Ticket
                </button>
              </h2>
              <Collapse
                isOpen={borderCol8}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse8"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      Por favor, antes de abrir um ticket, verifique com seu
                      fornecedor em caso de problemas.
                    </li>
                    <br />
                    <li>
                      Faça testes antes de abrir o ticket e fale com seu
                      fornecedor antes da abertura do mesmo.
                    </li>
                    <br />
                    <li>
                      Senhores revendedores, por favor, mantenham seus dados de
                      contato atualizados.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample9">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol9,
                  })}
                  type="button"
                  onClick={t_borderCol9}
                  style={{ cursor: "pointer" }}
                >
                  Fake News
                </button>
              </h2>
              <Collapse
                isOpen={borderCol9}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse9"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      Há várias notícias rondando na internet sobre informações
                      infundadas de diversos servidores, principalmente em
                      grupos de IPTV.
                    </li>
                    <br />
                    <li>
                      Que fique claro para todos, qualquer informação importante
                      que tivermos será passada na mesma hora para todos.
                      Primeiro será encaminhada para seu fornecedor de créditos
                      para chegar até você.
                    </li>
                    <br />
                    <li>
                      Nós não postamos informação em grupos de fofocas, então
                      fiquem tranquilos e parem de fazer especulações sobre
                      essas fake news.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample10">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol10,
                  })}
                  type="button"
                  onClick={t_borderCol10}
                  style={{ cursor: "pointer" }}
                >
                  Alguns Filmes não funcionam
                </button>
              </h2>
              <Collapse
                isOpen={borderCol10}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse10"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      Dentro do aplicativo, possui em configurações duas opções
                      de player: Player Padrão e ExoPlayer.
                    </li>
                    <br />
                    <li>
                      Cada TV box pode trabalhar melhor com uma das opções. Faça
                      o teste e veja qual é melhor para seu cliente.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample11">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol11,
                  })}
                  type="button"
                  onClick={t_borderCol11}
                  style={{ cursor: "pointer" }}
                >
                  Canal fica com a tela preta repentinamente
                </button>
              </h2>
              <Collapse
                isOpen={borderCol11}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse11"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      Os canais são gerados no Brasil, são enviados para
                      servidores fora do Brasil (em geral para os EUA), depois
                      são enviados para os servidores de venda e, por fim, do
                      servidor é enviado até o cliente final.
                    </li>
                    <br />
                    <li>
                      Se ocorre um erro na fonte de canais, ou no caminho entre
                      fonte -&gt; servidor ou servidor -&gt; cliente final, e o
                      canal sofre uma queda repentina no P2P, essa falha causa a
                      tela preta, e só irá voltar se o cliente sair do canal e
                      voltar.
                    </li>
                    <br />
                    <li>
                      Isso afeta todos que usam o mesmo tipo de aplicativo P2P.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionborderedExample12">
                <button
                  className={classnames("accordion-button", {
                    collapsed: !borderCol12,
                  })}
                  type="button"
                  onClick={t_borderCol12}
                  style={{ cursor: "pointer" }}
                >
                  Login do Usuário Repetido
                </button>
              </h2>
              <Collapse
                isOpen={borderCol12}
                className="accordion-collapse"
                id="accor_borderedExamplecollapse12"
              >
                <div className="accordion-body text">
                  <ul>
                    <li>
                      No P2P, se o cliente faz login em mais de um aparelho (TV
                      box + celular) e ele não faz LOGOUT (fecha o aplicativo
                      100%), pode ocorrer esse erro. Só irá funcionar se
                      deslogar de todos os aparelhos.
                    </li>
                    <br />
                    <li>
                      Se você está assistindo no celular e, de repente, apenas
                      vai para outro aplicativo, o P2P segue funcionando em
                      background.
                    </li>
                  </ul>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </div>
      </CardBody>
    </Card>
  );
};

export default Regras;
