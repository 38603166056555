import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import img from "../../assets/images/credenciais.png";
import FeatherIcon from "feather-icons-react";

const ModalMercadoPago = ({ modalMercadoPago, setModalMercadoPago }) => {
  const toggle = () => {
    setModalMercadoPago(!modalMercadoPago);
  };

  return (
    <Modal
      isOpen={modalMercadoPago}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Como configurar o Mercado Pago
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <p className="mb-3">
          <b>1.</b> Para configurar o Mercado Pago, siga os passos dos links
          abaixo:
        </p>
        <p className="mb-3">
          <a
            className="link-warning"
            href="https://www.mercadopago.com.br/developers/panel/app"
            target="_blank"
            rel="noreferrer"
          >
            Painel do Desenvolvedor Mercado Pago (faça login)
          </a>
        </p>
        <p className="mb-3">
          <a
            className="link-warning"
            href="https://www.mercadopago.com.br/pix-keys/app/key-management"
            target="_blank"
            rel="noreferrer"
          >
            Crie uma chave PIX para sua conta do Mercado Pago
          </a>
        </p>
        <p className="mb-3">
          <a
            className="link-warning"
            href="https://www.mercadopago.com.br/developers/pt/support/20152"
            target="_blank"
            rel="noreferrer"
          >
            Como Criar um aplicativo no Mercado Pago
          </a>
        </p>

        <p className="mb-3">
          <b>2.</b> Ao finalizar, clique no seu aplicativo, vá em{" "}
          <span className="text-primary">
            Produção &gt; Credenciais de Produção
          </span>{" "}
          e copie as informações das credenciais. (Passe o mouse por cima e
          clique no ícone <FeatherIcon icon="copy" size="16" /> no canto direito
          para copiar)
        </p>
        <img src={img} alt="credenciais" className="img-fluid mb-3" />

        <p className="mb-3">
          <b>3.</b> Cole as credenciais nos campos correspondentes desta página.
          Não esqueça de preencher os valores de crédito para Cliente e
          Revendedor.
        </p>

        <p className="mb-3 text-warning">
          Para o momento estamos apenas criando QR Code para pagamento de
          créditos de revendedores.
        </p>
      </ModalBody>
    </Modal>
  );
};

export default ModalMercadoPago;
