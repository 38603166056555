import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarMeses = ({
  modalAdicionarMeses,
  setModalAdicionarMeses,
  setRefresh,
  cliente,
  converterData,
}) => {
  const [loading, setLoading] = useState(false);
  const [mesesAdicionar, setMesesAdicionar] = useState("1");

  const calcularPreco = (cliente, mesesAdicionar) => {
    if(!cliente) return 0;
    const meses = mesesAdicionar === "" ? "0" : mesesAdicionar;

    const conexoes = cliente?.painel_tipo === 0 ? 1 : cliente?.conexoes;
    const precoOperacao =
      parseInt(meses) *
      conexoes *
      cliente?.painel?.preco_credito;
      
      return precoOperacao;
  };

  const renovar = async (clienteId) => {
    setLoading(true);
    const url = `/clientes/${clienteId}/${mesesAdicionar}/renovar`;

    const response = await apiClient.put(url, {});
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setMesesAdicionar("1");
      setModalAdicionarMeses(!modalAdicionarMeses);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalAdicionarMeses}
      toggle={() => {
        setModalAdicionarMeses(!modalAdicionarMeses);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarMeses(!modalAdicionarMeses);
        }}
      >
        Renovar {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="meses" className="form-label">
                  Meses a renovar
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="meses"
                  value={mesesAdicionar}
                  onChange={(e) =>
                    setMesesAdicionar(e.target.value.replace(/\D/g, ""))
                  }
                />
                <small className="text-muted">
                  <b>Vencimento Atual</b>: {converterData(cliente?.expiracao)}
                  <br />
                  <b>Custo</b>: {calcularPreco(cliente, mesesAdicionar).toFixed(2)} crédito(s)
                </small>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => setModalAdicionarMeses(!modalAdicionarMeses)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => renovar(cliente?.id)}
              disabled={loading}
            >
              Renovar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarMeses;
