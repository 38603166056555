import React, { useState, useCallback, useEffect } from "react";
import { Col, Row, Spinner, Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ImportarLista = () => {
  const [linkLista, setLinkLista] = useState("");
  const [dominios, setDominios] = useState([]);
  const [dadosLista, setDadosLista] = useState({});
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState(0);

  const fetchMigrar = useCallback(async () => {
    const response = await apiClient.get(`/clientes/migracao-free/status`);
    if (response.sucesso) {
      setStatus(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
      await fetchMigrar();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const handleLinkLista = async (e) => {
    e.preventDefault();
    setLoading(true);
    const pattern = new RegExp(
      `(https?://)?(.*?)/(.*)/get.php\\?username=(.*)&password=(.*)&type=(.*)&output=(.*)`,
      "i"
    );

    const match = linkLista.match(pattern);
    const isInvalid = dominios.some((dominio) => {
      const [, dom] = dominio.dominio.split("//");
      if (!match || linkLista.includes(dom)) {
        return true;
      }
      return false;
    });

    if (isInvalid) {
      toast.error("Link inválido ou lista já é deste servidor.");
      setLoading(false);
      return;
    }

    const [, , , , username, password] = match;
    const novoLink = linkLista.replace("get.php", "player_api.php");

    try {
      const response = await apiClient.post(
        "/clientes/migracao/check-link-data",
        {
          url: novoLink,
        }
      );
      if (response.sucesso) {
        const data = response.dados;

        if (!data.user_info || !data.user_info.exp_date) {
          toast.error("Não foram encontrados dados válidos na lista.");
          return;
        }

        const expDate = new Date(data.user_info.exp_date * 1000);
        const now = new Date();
        const diffDays = Math.ceil((expDate - now) / (1000 * 60 * 60 * 24));
        const dias = diffDays < 1 ? 0 : diffDays;

        if (username && password) {
          setDadosLista({ username, password, dias });
        } else {
          toast.error("Não foi possível extrair os dados da lista.");
        }
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    } catch (error) {
      toast.error("Houve um erro ao processar a lista.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const criar = async (username, password, dias) => {
    setLoading(true);

    const data = {
      nome: username,
      username,
      password,
      dias,
      painel_tipo: 1,
      planos: [31],
      conexoes: 1,
      observacao: "Migrado de Lista externa",
    };

    const response = await apiClient.post("/clientes", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      window.location.href = "/clientes";
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDominios = async () => {
      await fetchData();
    };
    if (loading) {
      fetchDominios();
    }
  }, [loading]);

  return (
    <Col lg={6} xxl={4} className="mb-3">
      <div className="card card-body" style={{ height: "100%" }}>
        <div className="avatar-sm mx-auto mb-3">
          <div className="avatar-title bg-success-subtle text-success fs-17 rounded">
            <i className="ri-add-line"></i>
          </div>
        </div>
        <h4 className="card-title text-center">Importar Lista</h4>
        <Row style={{ height: "100%" }}>
          <Col md={12} className="text-center">
            {dadosLista.username ? (
              <>
                <Row className="justify-content-start text-left mb-3 mt-3 pe-3 ps-3">
                  <Col lg={6}>
                    <strong className="text-warning">Usuário:</strong>{" "}
                    {dadosLista.username}
                  </Col>

                  <Col lg={6}>
                    <strong className="text-warning">Senha:</strong>{" "}
                    {dadosLista.password}
                  </Col>

                  <Col lg={6}>
                    <strong className="text-warning">Dias Restantes:</strong>{" "}
                    {dadosLista.dias}
                  </Col>
                  <Col lg={6}>
                    <strong className="text-warning">Créditos a gastar:</strong>{" "}
                    {status ? 0 : (dadosLista.dias * (0.8 / 30)).toFixed(2)}
                  </Col>
                </Row>
                <Row className="justify-content-center text-warning mt-3 p-4">
                  Se as informações estiverem incorretas, NÃO FAÇA A MIGRAÇÃO!
                  Contate o seu revendedor, mande um print dessa tela e informe
                  a lista que tentou importar.
                </Row>

                {status && status !== 0 && dadosLista.dias > 30 ? (
                  <Row className="justify-content-center text-danger mt-3 p-4">
                    O limite de dias gratuitos é de 30 dias. Se a lista tiver
                    mais de 30 dias restantes, o cliente será criado com 30 dias
                    apenas.
                  </Row>
                ) : null}

                <button
                  className="btn btn-info"
                  onClick={() => {
                    const criarCall = async () => {
                      await criar(
                        dadosLista.username,
                        dadosLista.password,
                        dadosLista.dias
                      );
                    };
                    criarCall();
                  }}
                  disabled={loading}
                >
                  Criar Cliente
                </button>
              </>
            ) : (
              <>
                {loading ? (
                  <Row className="justify-content-center mb-3">
                    <Spinner color="primary" />
                  </Row>
                ) : (
                  <>
                    <p className="card-text text-center">
                      Importe aqui uma lista de IPTV de um outro servidor para
                      sua base. Gastará a quantidade de créditos correspondente
                      ao número de dias restantes na lista.
                    </p>
                    <small className="card-text text-center">
                      Use a lista no padrão{" "}
                      <b className="text-warning">
                        http://LINK_DO_SERVIDOR/get.php?username=USUARIO
                        &password=SENHA&type=m3u_plus&output=ts
                      </b>
                      .
                    </small>
                    {status && status !== 0 ? (
                      <p className="card-text text-center text-success mt-3">
                        Migração gratuita está ativada. Não gastará créditos.
                      </p>
                    ) : null}
                    <Input
                      type="text"
                      className="form-control mb-3 mt-2"
                      id="link"
                      placeholder="Link da lista"
                      value={linkLista}
                      onChange={(e) => setLinkLista(e.target.value)}
                      maxLength={300}
                    />{" "}
                  </>
                )}
                <button
                  className="btn btn-success"
                  onClick={handleLinkLista}
                  disabled={loading}
                >
                  Adicionar
                </button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default ImportarLista;
