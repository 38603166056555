import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalAdicionarCreditos = ({
  modalAdicionarCreditos,
  setModalAdicionarCreditos,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [creditosAdicionar, setCreditosAdicionar] = useState("5");

  const adicionar = async () => {
    setLoading(true);

    const response = await apiClient.put(
      `/revendedores/adicionar-creditos/${revendedor.id}`,
      {
        creditos: creditosAdicionar === "" ? 0 : parseInt(creditosAdicionar),
      }
    );

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setCreditosAdicionar(0);
      setModalAdicionarCreditos(!modalAdicionarCreditos);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalAdicionarCreditos}
      toggle={() => {
        setModalAdicionarCreditos(!modalAdicionarCreditos);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalAdicionarCreditos(!modalAdicionarCreditos);
        }}
      >
        Adicionar Créditos a {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="creditos" className="form-label">
                  Créditos a adicionar (Atual: {revendedor?.creditos})
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="creditos"
                  value={creditosAdicionar}
                  onChange={(e) =>
                    setCreditosAdicionar(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={8}
                />
                <small className="text-muted">Recarga mínima de 5 créditos</small>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => setModalAdicionarCreditos(!modalAdicionarCreditos)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => adicionar()}
              disabled={loading}
            >
              Adicionar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdicionarCreditos;
