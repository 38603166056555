import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import SearchOption from "./SearchOption";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";
import ModalNovo from "./ModalNovo";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const MagDevices = () => {
  document.title = "MAG Devices | Dark Store";

  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);

  const [mag, setMag] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [mags, setMagDevices] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [totalDeMags, setTotalDeMags] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("vencimento");
  const [orderAsc, setOrderAsc] = useState(false);

  const [selecionados, setSelecionados] = useState([]);

  const tratarStatus = (cliente) => {
    if (cliente.status === 1) {
      const dataExpiracao = new Date(cliente.expiracao.replace(" ", "T"));
      const dataAtual = new Date();
      const diferenca = dataExpiracao - dataAtual;
      const dias = diferenca / (1000 * 60 * 60 * 24);
      if (dias < 0) {
        return "Esgotado";
      } else if (dias < 3) {
        return "Esgotando";
      } else {
        return "Ativo";
      }
    } else if (cliente.status === 2) {
      return "Bloqueado";
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const todosIds = mags.map((mag) => mag.id);
      setSelecionados(todosIds);
    } else {
      setSelecionados([]);
    }
  };

  const handleSelectSingle = (e, id) => {
    if (e.target.checked) {
      setSelecionados((prevSelecionados) => [...prevSelecionados, id]);
    } else {
      setSelecionados((prevSelecionados) =>
        prevSelecionados.filter((sid) => sid !== id)
      );
    }
  };

  const toggleModalEditar = useCallback(async (mag) => {
    setMag(mag);
    setModalEditar(true);
  }, []);

  const toggleModalApagar = useCallback(async (mag) => {
    setMag(mag);
    setModalApagar(true);
  }, []);

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const calcularPaginas = () => {
    const paginas = [];
    const paginasVisiveis = 2;

    // Adicionar as três primeiras páginas
    for (let i = 1; i <= Math.min(paginasVisiveis, numeroDePaginas); i++) {
      paginas.push(i);
    }

    let inicio = Math.max(paginaAtual - paginasVisiveis, paginasVisiveis + 1);
    let fim = Math.min(
      paginaAtual + paginasVisiveis,
      numeroDePaginas - paginasVisiveis
    );

    if (inicio > paginas[paginas.length - 1] + 1) {
      paginas.push("...");
    }

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    if (fim < numeroDePaginas - paginasVisiveis) {
      paginas.push("...");
    }

    // Adicionar as três últimas páginas
    for (
      let i = Math.max(
        numeroDePaginas - paginasVisiveis + 1,
        paginasVisiveis + 1
      );
      i <= numeroDePaginas;
      i++
    ) {
      if (!paginas.includes(i)) {
        paginas.push(i);
      }
    }

    return paginas;
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchMagDevices = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca, orderBy, orderAsc) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
      };

      const response = await apiClient.post("/mag/busca", data);

      if (response.sucesso) {
        const dados = response.dados;
        setMagDevices(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeMags(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchMagDevices(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        orderBy,
        orderAsc
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    orderBy,
    orderAsc,
    refresh,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="MAG Devices" pageTitle="Grupos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>
          <Row>
            <b>Legendas dos botões</b>
            <Col xl={2}>
              <small className="text-muted text-left mb-2">
                <FeatherIcon
                  icon="edit"
                  width="13"
                  className="me-3 text-secondary"
                />
                Editar
              </small>
            </Col>
            <Col xl={2}>
              <small className="text-muted text-left mb-2">
                <FeatherIcon
                  icon="trash-2"
                  width="13"
                  className="me-3 text-danger"
                />
                Apagar
              </small>
            </Col>
          </Row>
          <Row className="mt-3">
            <small className="text-muted text-left mb-2">
              {selecionados.length} dispositivos selecionados
            </small>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Seus MAG Devices
                  </h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-soft-secondary"
                      onClick={() => toggleModalNovo()}
                    >
                      Novo Dispositivo
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  {!loading && !loadTable && mags.length === 0 ? null : (
                    <div className="align-items-center mt-2 mb-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeMags
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeMags
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeMags}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "46px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="cardtableCheck"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={
                                      selecionados.length === mags.length
                                    }
                                    ref={(el) =>
                                      el &&
                                      (el.indeterminate =
                                        selecionados.length > 0 &&
                                        selecionados.length < mags.length)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cardtableCheck"
                                  ></label>
                                </div>
                              </th>
                              <th scope="col" style={{ width: "20%" }}>
                                Revendedor
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Cliente
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Mac Address
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Vencimento
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Status
                              </th>
                              <th scope="col" style={{ width: "20%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {mags.length !== 0 &&
                              mags.map((mag, index) => {
                                const clienteStatus = tratarStatus(mag.cliente);
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={mag.id}
                                          id={`cardtableCheck-${index}`}
                                          onChange={(e) =>
                                            handleSelectSingle(e, mag.id)
                                          }
                                          checked={selecionados.includes(
                                            mag.id
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cardtableCheck01"
                                        ></label>
                                      </div>
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>{mag.revendedor?.username || ""}</b>
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0">
                                        <b>{mag.cliente?.username || ""}</b>
                                      </p>

                                      {mag.cliente?.online === 1 &&
                                        (clienteStatus === "Ativo" ||
                                          clienteStatus === "Esgotando") && (
                                          <>
                                            <i className="mdi mdi-circle-medium text-success fs-15 align-middle"></i>
                                            <small className="text-muted">
                                              On-line
                                            </small>
                                          </>
                                        )}
                                    </td>
                                    <td>
                                      <p className="mb-0">{mag.mac_address}</p>
                                    </td>
                                    <td>
                                      {converterData(mag.cliente?.expiracao)}
                                    </td>
                                    <td>
                                      <span
                                        className={`badge bg-${
                                          clienteStatus === "Ativo"
                                            ? "success"
                                            : clienteStatus === "Esgotado"
                                            ? "danger"
                                            : clienteStatus === "Esgotando"
                                            ? "primary"
                                            : clienteStatus === "Bloqueado"
                                            ? "info"
                                            : "dark"
                                        }`}
                                      >
                                        {clienteStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-secondary"
                                        title="Editar"
                                        onClick={() => toggleModalEditar(mag)}
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="18"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        title="Apagar Mag"
                                        onClick={() => {
                                          toggleModalApagar(mag);
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="18"
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && mags.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum dispositivo MAG encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeMags
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeMags
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeMags}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalEditar
            modalEditar={modalEditar}
            setModalEditar={setModalEditar}
            setRefresh={setRefresh}
            mag={mag}
          />
          <ModalApagar
            modalApagar={modalApagar}
            setModalApagar={setModalApagar}
            setRefresh={setRefresh}
            magDados={mag}
          />
          <ModalNovo
            modalNovo={modalNovo}
            setModalNovo={setModalNovo}
            setRefresh={setRefresh}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default MagDevices;
