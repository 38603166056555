import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Row, Button} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import ModalAdicionarMeses from "../Clientes/ModalAdicionarMeses";

const UsersExpirados = ({ clientes, loading, converterData, setRefresh }) => {
  const itensPorPagina = 5;
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(
    Math.ceil(clientes?.length / itensPorPagina)
  );
  const [clientesNaPaginaAtual, setTestesNaPaginaAtual] = useState(
    clientes?.slice(
      (paginaAtual - 1) * itensPorPagina,
      paginaAtual * itensPorPagina
    )
  );

  const [modalAdicionarMeses, setModalAdicionarMeses] = useState(false);
  const [cliente, setCliente] = useState(null);

  const toggleModalAdicionarMeses = useCallback(async (cli) => {
    setCliente(cli);
    setModalAdicionarMeses(true);
  }, []);

  useEffect(() => {
    if (!loading) {
      setNumeroDePaginas(Math.ceil(clientes?.length / itensPorPagina));
      setTestesNaPaginaAtual(
        clientes?.slice(
          (paginaAtual - 1) * itensPorPagina,
          paginaAtual * itensPorPagina
        )
      );
    }
  }, [loading, clientes, paginaAtual]);
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Clientes Expirados</h4>
          </CardHeader>

          {loading ? (
            <Row className="justify-content-center mt-2 mb-2">
              <Spinner color="info" />
            </Row>
          ) : (
            <CardBody>
              <div className="table-responsive table-card">
                <table className="table table-centered table-hover align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-info">Nome de usuário</th>
                      <th className="text-info">Expiração</th>
                      <th className="text-info">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientesNaPaginaAtual.map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <b className="fs-14 my-1 fw-medium">
                                <span
                                  className="text-reset"
                                >
                                  {item.username}
                                </span>
                                <small className="d-block text-muted">
                                  {item.nome}
                                </small>
                              </b>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-danger">
                            {converterData(item.expiracao)}
                          </span>
                        </td>
                        <td>
                          <Button
                            color="primary"
                            className="btn-sm"
                            onClick={() => toggleModalAdicionarMeses(item)}
                            title="Renovar Cliente"
                          >
                            <FeatherIcon
                              icon="user-plus"
                              size="12"
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                <div className="col-sm">
                  <div className="text-muted">
                    Mostrando{" "}
                    <span className="fw-semibold">
                      {Math.min(
                        (paginaAtual - 1) * itensPorPagina + 1,
                        clientes?.length
                      )}
                    </span>{" "}
                    a{" "}
                    <span className="fw-semibold">
                      {Math.min(paginaAtual * itensPorPagina, clientes?.length)}
                    </span>{" "}
                    de <span className="fw-semibold">{clientes?.length}</span>{" "}
                    resultados
                  </div>
                </div>
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item ${
                        paginaAtual === 1 ? "disabled" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => setPaginaAtual(paginaAtual - 1)}
                      >
                        ←
                      </Link>
                    </li>
                    {Array.from({ length: numeroDePaginas }, (_, i) => (
                      <li
                        key={i}
                        className={`page-item ${
                          i + 1 === paginaAtual ? "active" : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => setPaginaAtual(i + 1)}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        paginaAtual === numeroDePaginas ? "disabled" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => setPaginaAtual(paginaAtual + 1)}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          )}
        </Card>


        <ModalAdicionarMeses
            modalAdicionarMeses={modalAdicionarMeses}
            setModalAdicionarMeses={setModalAdicionarMeses}
            setRefresh={setRefresh}
            cliente={cliente}
            converterData={converterData}
          />
      </Col>
    </React.Fragment>
  );
};

export default UsersExpirados;
