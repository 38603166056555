import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { checkLastLogin } from "../FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

//import images
import bell from "../../assets/images/svg/bell.svg";
import { toast } from "react-toastify";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  const [activeTab, setActiveTab] = useState("1");

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  function limparMensagem(input) {
    const semTagsHtml = input.replace(/<[^>]*>/g, "");

    return semTagsHtml;
  }

  function getRandomClass() {
    const classes = [
      "danger",
      "warning",
      "primary",
      "secondary",
      "dark",
      "info",
      "success",
    ];
    const randomIndex = Math.floor(Math.random() * classes.length);
    return classes[randomIndex];
  }

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/tickets/notificacao/get");
    if (response.sucesso) {
      setTickets(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [fetchData]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {tickets.length}
            <span className="visually-hidden">mensagens não lidas</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notificações{" "}
                  </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge bg-light-subtle text-body fs-13">
                    {" "}
                    {tickets.length} Novas
                  </span>
                </div>
              </Row>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            {tickets.length === 0 ? (
              <TabPane tabId="1" className="p-4">
                <div className="w-25 w-sm-50 pt-3 mx-auto">
                  <img src={bell} className="img-fluid" alt="user-pic" />
                </div>
                <div className="text-center pb-5 mt-2">
                  <h6 className="fs-18 fw-semibold lh-base">
                    Você não tem notificações{" "}
                  </h6>
                </div>
              </TabPane>
            ) : (
              <TabPane tabId="1" className="py-2 ps-2">
                <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                  {tickets.map((ticket, key) => (
                    <div
                      className="text-reset notification-item d-block dropdown-item"
                      key={key}
                    >
                      <div className="d-flex">
                        <FeatherIcon
                          className={`me-3 rounded-circle avatar-xs text-${getRandomClass()}`}
                          icon="user"
                          width="100%"
                        />
                        <div className="flex-grow-1">
                          <Link to="/suporte" className="stretched-link">
                            <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                              {ticket.user.nome}
                            </h6>
                          </Link>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">
                              {limparMensagem(ticket.mensagem)}
                            </p>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {converterData(ticket.created)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </SimpleBar>
              </TabPane>
            )}
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
