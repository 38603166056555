import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import SearchOption from "./SearchOption";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import classnames from "classnames";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Clientes = () => {
  document.title = "Clientes On-Line | Dark Store";

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [clientes, setClientes] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [painelTipo, setPainelTipo] = useState("-1");
  const [totalDeClientes, setTotalDeClientes] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("expiracao");
  const [orderAsc, setOrderAsc] = useState(false);

  const arrowNavToggle = (tab) => {
    if (painelTipo !== tab) {
      setPainelTipo(tab);
      setPaginaAtual(1);
      setLoading(true);
    }
  };

  const fetchClientes = useCallback(
    async (
      itensPorPagina,
      paginaAtual,
      termoBusca,
      painelTipo,
      orderBy,
      orderAsc
    ) => {
      const data = {
        status: "Ativo",
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        painel_tipo: painelTipo,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
        online_data: true,
      };

      const response = await apiClient.post("/clientes/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setClientes(dados.data);
        setTotalDeClientes(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchClientes(
        itensPorPagina,
        paginaAtual,
        termoBusca,
        painelTipo,
        orderBy,
        orderAsc
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    termoBusca,
    painelTipo,
    orderBy,
    orderAsc,
    refresh,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Clientes On-Line" pageTitle="Utiitários" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>
          <Nav
            pills
            className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "-1",
                })}
                onClick={() => {
                  arrowNavToggle("-1");
                }}
              >
                Todos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "1",
                })}
                onClick={() => {
                  arrowNavToggle("1");
                }}
              >
                IPTV
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: painelTipo === "0",
                })}
                onClick={() => {
                  arrowNavToggle("0");
                }}
              >
                P2P
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Clientes on-line
                  </h4>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "25%" }}>
                                Nome
                              </th>
                              <th scope="col" style={{ width: "25%" }}>
                                Canal
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Dispositivo
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Tempo Online
                              </th>
                              <th scope="col" style={{ width: "10%" }}>
                                Sistema
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Revendedor
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {clientes.length !== 0 &&
                              clientes.map((cliente, index) => {
                                const dadosOnline = JSON.parse(
                                  cliente.online_data?.data || "{}"
                                );
                                console.log(cliente);
                                // converter o tempo (duration) em segundos para o formato 00:00:00
                                const converterDuration = (duration) => {
                                  const hours = Math.floor(duration / 3600);
                                  const minutes = Math.floor(
                                    (duration % 3600) / 60
                                  );
                                  const seconds = Math.floor(duration % 60);
                                  return `${hours}:${minutes}:${seconds}`;
                                };
                                return (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{cliente.nome}</b>
                                      </p>
                                      <small className="text-muted">
                                        <i className="mdi mdi-circle-medium text-success fs-15 align-middle"></i>
                                        {cliente.username || ""}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {dadosOnline?.channel_name ||
                                        "Indisponível"}
                                      <br />
                                      <small className="text-muted">
                                        {dadosOnline?.external_ip}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        {dadosOnline?.device_model}
                                      </p>
                                      <small className="text-muted">
                                        Versão App:{" "}
                                        {dadosOnline?.app_version ===
                                        dadosOnline?.device_model
                                          ? "Indisponível"
                                          : dadosOnline?.app_version}
                                      </small>
                                      <br />
                                      <small className="text-muted">
                                        Versão OS:{" "}
                                        {dadosOnline?.os_version ===
                                        dadosOnline?.device_model
                                          ? "Indisponível"
                                          : dadosOnline?.os_version}
                                      </small>
                                    </td>
                                    <td>
                                      {dadosOnline?.duration
                                        ? converterDuration(
                                            dadosOnline?.duration
                                          )
                                        : "Indisponível"}
                                    </td>
                                    <td>
                                      <span
                                        className={`badge bg-${
                                          cliente.painel_tipo === 1
                                            ? "success"
                                            : cliente.painel_tipo === 0
                                            ? "secondary"
                                            : "dark"
                                        }`}
                                      >
                                        {cliente.painel_tipo === 1
                                          ? "IPTV"
                                          : cliente.painel_tipo === 0
                                          ? "P2P"
                                          : ""}
                                      </span>
                                    </td>
                                    <td>{cliente.parent?.username}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && clientes.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum cliente encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeClientes
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeClientes
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeClientes}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeClientes}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Clientes;
