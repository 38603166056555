import React from "react";
import { Input } from "reactstrap";

const SearchOption = ({
  setTermoBusca,
  termoBusca,
  setLoading,
  setLoadTable,
  setPaginaAtual
}) => {
  return (
    <React.Fragment>
      <form className="app-search">
        <div className="position-relative">
          <Input
            type="text"
            className="form-control"
            placeholder="Buscar..."
            id="revenda-search"
            value={termoBusca}
            onChange={(e) => {
              setTermoBusca(e.target.value);
              setPaginaAtual(1);
              setLoadTable(true);
              setTimeout(() => {
                setLoading(true);
              }, 700);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
            id="search-close-options"
          ></span>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchOption;
