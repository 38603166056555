import React from "react";
import { Col, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const Legendas = () => {
  return (
    <Row>
      <b>Legendas dos botões</b>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="download"
            width="13"
            className="me-3 text-danger"
          />
          Download de Lista
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="edit" width="13" className="me-3 text-success" />
          Editar
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="lock" width="13" className="me-3 text-info" />
          Bloquear
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="user-plus"
            width="13"
            className="me-3 text-primary"
          />
          Renovar
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="user" width="13" className="me-3 text-success" />
          Mudar Revendedor
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="tv" width="13" className="me-3 text-info" />
          Telas
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="calendar"
            width="13"
            className="me-3 text-warning"
          />
          Prorrogar
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="rotate-cw"
            width="13"
            className="me-3 text-secondary"
          />
          Renovar 1 mês
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="refresh-cw"
            width="13"
            className="me-3 text-warning"
          />
          Recriar / Reiniciar Conexões
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon
            icon="repeat"
            width="13"
            className="me-3"
            style={{ color: "white" }}
          />
          Migrar
        </small>
      </Col>
      <Col xl={2}>
        <small className="text-muted text-left mb-2">
          <FeatherIcon icon="trash-2" width="13" className="me-3 text-danger" />
          Apagar
        </small>
      </Col>
    </Row>
  );
};

export default Legendas;
