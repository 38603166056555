import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalMudarSenha = ({
  modalMudarSenha,
  setModalMudarSenha,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userDados, setUserDados] = useState({});

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const mudarSenha = async () => {
    setLoading(true);
    if (password !== passwordConfirm) {
      toast.error("As senhas não coincidem.");
      setLoading(false);
      return;
    }

    const data = {
      password,
      password_confirmation: passwordConfirm,
      motivo,
    };

    const response = await apiClient.post(
      `/revendedores/mudar-senha/${revendedor.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem || "Senha alterada com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setPassword("");
      setPasswordConfirm("");
      setMotivo("");
      setModalMudarSenha(!modalMudarSenha);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalMudarSenha}
      toggle={() => {
        setModalMudarSenha(!modalMudarSenha);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalMudarSenha(!modalMudarSenha);
        }}
      >
        Mudar Senha de {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row>
            <div>
              <Label htmlFor="senha" className="form-label">
                Nova Senha
              </Label>
              <Input
                type="text"
                className="form-control"
                id="senha"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordConfirm(e.target.value);
                }}
              />
              <small className="text-muted">
                Limite mínimo de 8 caracteres.
              </small>
            </div>
          </Row>

          {userDados?.id !== 1 && (
            <Row className="mt-3">
              <div>
                <Label htmlFor="motivo" className="form-label">
                  Motivo
                </Label>
                <Input
                  type="textarea"
                  className="form-control"
                  id="motivo"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                  maxLength={255}
                />
                <small className="text-muted">
                  Este formulário envia um pedido para mudar a senha do
                  revendedor. O pedido será analisado e, se aprovado, a senha
                  será alterada.
                </small>
              </div>
            </Row>
          )}
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-warning fw-medium"
              onClick={() => setModalMudarSenha(!modalMudarSenha)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-warning"
              onClick={() => mudarSenha()}
              disabled={loading}
            >
              Mudar Senha
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMudarSenha;
