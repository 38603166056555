import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import InputMask from "react-input-mask";
import Select from "react-select";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovo = ({ modalNovo, setModalNovo, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState(null);

  function handleSelectSingle(cliente) {
    setCliente(cliente);
  }

  const criar = async () => {
    setLoading(true);

    const data = {
      mac_address: macAddress,
      user_id: cliente.value,
      nome: nome,
    };

    const response = await apiClient.post("/utm_pro/addplaylist", data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setNome("");
      setMacAddress("");
      setCliente({});
      setModalNovo(!modalNovo);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/utm_pro/clientes");

    if (response.sucesso) {
      const dados = response.dados;
      let options = [];
      dados.map((cliente) => {
        options.push({ value: cliente.id, label: cliente.externo_login });
      });
      setClientes([{ options: options }]);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const runData = async () => {
      await fetchData();
    };
    if (modalNovo) {
      runData();
    }
  }, [modalNovo, fetchData]);
  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        setModalNovo(!modalNovo);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalNovo(!modalNovo);
        }}
      >
        Novo Device
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="macAddress" className="form-label">
                  Mac Address
                </Label>
                <InputMask
                  mask="aa:aa:aa:aa:aa:aa"
                  placeholder="00:00:00:00:00:00"
                  defaultValue={macAddress}
                  onChange={(e) => setMacAddress(e.target.value)}
                  formatChars={{
                    a: "[0-9A-Fa-f]",
                  }}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      id="macAddress"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="cliente" className="form-label">
                  Cliente
                </Label>
                {loading ? (
                  <Input
                    type="text"
                    placeholder="Carregando..."
                    className="form-control"
                    disabled
                  />
                ) : (
                  <Select
                    value={cliente}
                    onChange={(selectedOption) => {
                      handleSelectSingle(selectedOption);
                    }}
                    placeholder="Selecione um cliente"
                    options={clientes}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#1f2429",
                        border: "1px solid #2a4661",
                        color: "#aaa",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#aaa",
                      }),
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => setModalNovo(!modalNovo)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
