import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalRenovar = ({
  modalRenovar,
  setModalRenovar,
  setRefresh,
  utmDados,
}) => {
  const [loading, setLoading] = useState(false);
  const [deviceKey, setDeviceKey] = useState("");
  const [renewType, setRenewType] = useState("semi_annual");
  const [macAddress, setMacAddress] = useState("");

  const renovar = async (utm) => {
    setLoading(true);

    const data = {
      device_id: utm,
      renew_type: renewType,
      device_key: deviceKey,
    };

    const response = await apiClient.post("/utm/renewdevice", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setDeviceKey("");
      setRefresh(true);
      setModalRenovar(!modalRenovar);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (modalRenovar) {
      setDeviceKey(utmDados?.device_key || "");
      setMacAddress(utmDados?.mac_address);
    }
  }, [modalRenovar, utmDados]);
  return (
    <Modal
      isOpen={modalRenovar}
      toggle={() => {
        setModalRenovar(!modalRenovar);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalRenovar(!modalRenovar);
        }}
      >
        Renovar {utmDados?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="macAddress" className="form-label">
                  Mac Address
                </Label>
                <Input
                  type="text"
                  id="macAddress"
                  className="form-control"
                  placeholder="00:00:00:00:00:00"
                  value={macAddress}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="device_key" className="form-label">
                  Device Key <span className="text-danger">*</span>
                </Label>
                <Input
                  id="device_key"
                  className="form-control"
                  type="text"
                  placeholder="000000"
                  value={deviceKey}
                  onChange={(e) =>
                    setDeviceKey(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={6}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="renew_type" className="form-label">
                  Tipo de Renovação
                </Label>
                <Input
                  id="renew_type"
                  class="form-control"
                  type="select"
                  value={renewType}
                  onChange={(e) => setRenewType(e.target.value)}
                >
                  <option value="semi_annual">6 Meses (1 crédito)</option>
                  <option value="annual">1 ano (2 créditos)</option>
                  <option value="lifetime">Vitalício (3 créditos)</option>
                </Input>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-warning fw-medium`}
              onClick={() => setModalRenovar(!modalRenovar)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-warning`}
              onClick={() => renovar(utmDados.id)}
              disabled={loading}
            >
              Renovar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalRenovar;
