import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link, useParams } from "react-router-dom";
import SearchOption from "./SearchOption";
import ModalEditar from "./ModalEditar";
import ModalAdicionarCreditos from "./ModalAdicionarCreditos";
import ModalAdicionarCreditosMassa from "./ModalAdicionarCreditosMassa";
import ModalRemoverCreditos from "./ModalRemoverCreditos";
import ModalNovo from "./ModalNovo";
import ModalBloquear from "./ModalBloquear";
import ModalApagar from "./ModalApagar";
import ModalMudarSenha from "./ModalMudarSenha";
import ModalMudarMaster from "./ModalMudarMaster";
import ModalCompose from "../Tickets/ModalCompose";
import Legendas from "./Legendas";
import { toast } from "react-toastify";
import classnames from "classnames";
import {
  checkLastLogin,
  trataCaminho,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const Revendedores = () => {
  document.title = "Revendedores | Dark Store";
  const { user_id } = useParams();

  const [modalEditar, setModalEditar] = useState(false);
  const [modalAdicionarCreditos, setModalAdicionarCreditos] = useState(false);
  const [modalAdicionarCreditosMassa, setModalAdicionarCreditosMassa] =
    useState(false);
  const [modalRemoverCreditos, setModalRemoverCreditos] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalBloquear, setModalBloquear] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalMudarMaster, setModalMudarMaster] = useState(false);
  const [modalMudarSenha, setModalMudarSenha] = useState(false);
  const [modalCompose, setModalCompose] = useState(false);

  const [revendedor, setRevendedor] = useState(0);
  const [revendedorPagina, setRevendedorPagina] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [userDados, setUserDados] = useState(null);
  const [revendedores, setRevendedores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [status, setStatus] = useState("Todos");
  const [verRelatorio, setVerRelatorio] = useState(false);
  const [base, setBase] = useState("meus");
  const [itensPorPagina, setItensPorPagina] = useState(20);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [totalDeRevendedores, setTotalDeRevendedores] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [orderBy, setOrderBy] = useState("parent_id");
  const [orderAsc, setOrderAsc] = useState(false);

  const [selecionados, setSelecionados] = useState([]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const todosIds = revendedores.map((revendedor) => revendedor.id);
      setSelecionados(todosIds);
    } else {
      setSelecionados([]);
    }
  };

  const handleSelectSingle = (e, id) => {
    if (e.target.checked) {
      setSelecionados((prevSelecionados) => [...prevSelecionados, id]);
    } else {
      setSelecionados((prevSelecionados) =>
        prevSelecionados.filter((sid) => sid !== id)
      );
    }
  };

  const toggleModalEditar = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalEditar(true);
  }, []);

  const toggleModalCompose = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalCompose(true);
  }, []);

  const toggleModalAdicionarCreditos = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalAdicionarCreditos(true);
  }, []);

  const toggleModalAdicionarCreditosMassa = useCallback(async () => {
    setModalAdicionarCreditosMassa(true);
  }, []);

  const toggleModalRemoverCreditos = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalRemoverCreditos(true);
  }, []);

  const toggleModalBloquear = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalBloquear(true);
  }, []);

  const toggleModalApagar = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalApagar(true);
  }, []);

  const toggleModalMudarMaster = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalMudarMaster(true);
  }, []);

  const toggleModalMudarSenha = useCallback(async (rev) => {
    setRevendedor(rev);
    setModalMudarSenha(true);
  }, []);

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const arrowNavToggle = (tab) => {
    if (base !== tab) {
      setBase(tab);
      setPaginaAtual(1);
      setLoading(true);
    }
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const tratarStatus = (revendedor) => {
    if (revendedor.status === 2) {
      return "Bloqueado";
    } else if (revendedor.creditos <= 0) {
      return "Esgotado";
    } else {
      return "Ativo";
    }
  };

  const fetchRevendedor = useCallback(async (userId) => {
    const response = await apiClient.get(`/revendedores/${userId}`);
    if (response.sucesso) {
      setRevendedorPagina(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchRevendedores = useCallback(
    async (
      statusRevendedor,
      itensPorPagina,
      paginaAtual,
      base,
      termoBusca,
      orderBy,
      orderAsc,
      relatorio,
      parentId
    ) => {
      const userData = JSON.parse(localStorage.getItem("authUser"));
      setUserDados(userData);

      const response = await apiClient.post("/revendedores/busca", {
        status: statusRevendedor,
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        base: base,
        order_by: orderBy,
        order: orderAsc ? "asc" : "desc",
        relatorio,
        parent_id: parentId,
      });

      if (response.sucesso) {
        const dados = response.dados;
        setRevendedores(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeRevendedores(dados.total);
        if (parentId) {
          await fetchRevendedor(parentId);
        }
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchRevendedores(
        status,
        itensPorPagina,
        paginaAtual,
        base,
        termoBusca,
        orderBy,
        orderAsc,
        verRelatorio,
        user_id
      );
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();

    setLoading(false);
  }, [
    loading,
    itensPorPagina,
    paginaAtual,
    base,
    termoBusca,
    status,
    orderBy,
    orderAsc,
    refresh,
    verRelatorio,
    user_id,
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Revendedores" pageTitle="Grupos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                  disabled={verRelatorio}
                  title={
                    verRelatorio
                      ? "Desabilitado quando relatório está ativo"
                      : ""
                  }
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={8}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setOrderBy("parent_id");
                    setOrderAsc(false);
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="Todos">Todos</option>
                  <option value="Ativo">Ativos</option>
                  <option value="Esgotado">Esgotados</option>
                  <option value="Bloqueado">Bloqueados</option>
                  <option value="Lixeira" className="text-danger">
                    Lixeira
                  </option>
                </select>
              </Col>
            </Row>
          </Card>
          <Legendas userDados={userDados} />
          {!user_id && (
            <Nav
              pills
              className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 mt-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "meus",
                  })}
                  onClick={() => {
                    arrowNavToggle("meus");
                  }}
                >
                  Meus revendas
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: base === "todos",
                  })}
                  onClick={() => {
                    arrowNavToggle("todos");
                  }}
                >
                  Todos
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <Row className="mt-3">
            <small className="text-muted text-left mb-2">
              {selecionados.length} revendedores selecionados
            </small>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {revendedorPagina
                      ? `Revendedores de ${revendedorPagina.nome} - Total: ${totalDeRevendedores} revendedores`
                      : `Seus Revendedores - Total: ${totalDeRevendedores} revendedores`}
                    {verRelatorio ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-soft-primary ms-5"
                        onClick={() => setVerRelatorio(false)}
                        title="Ocultar Relatório de revendedores e clientes"
                      >
                        <FeatherIcon icon="eye-off" width="13" /> Relatório
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary ms-5"
                        onClick={() => {
                          setItensPorPagina(20);
                          setVerRelatorio(true);
                        }}
                        title="Visualizar o relatório de revendedores e clientes pode deixar a página mais lenta. Use quando necessário."
                      >
                        <FeatherIcon icon="eye" width="13" /> Relatório
                      </button>
                    )}
                  </h4>
                  <div className="flex-shrink-0">
                    {selecionados.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-soft-primary me-2"
                        onClick={() => toggleModalAdicionarCreditosMassa()}
                      >
                        <FeatherIcon icon="plus-circle" width="13" /> Adicionar
                        Créditos
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-soft-secondary"
                      onClick={() => toggleModalNovo()}
                    >
                      Novo Revendedor
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  {status === "Lixeira" && (
                    <div className="align-items-center mb-4 justify-content-between row text-center text-sm-start">
                      <small className="text-muted mb-2">
                        Para retirar um revendedor da lixeira, adicione créditos
                        a ele.
                      </small>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "46px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="cardtableCheck"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={
                                      selecionados.length ===
                                      revendedores.length
                                    }
                                    ref={(el) =>
                                      el &&
                                      (el.indeterminate =
                                        selecionados.length > 0 &&
                                        selecionados.length <
                                          revendedores.length)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cardtableCheck"
                                  ></label>
                                </div>
                              </th>
                              <th
                                scope="col"
                                style={{ width: verRelatorio ? "20%" : "32%" }}
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("nome");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Nome
                                </Link>
                              </th>
                              {verRelatorio && (
                                <th scope="col" style={{ width: "12%" }}>
                                  Relatório
                                </th>
                              )}
                              <th scope="col" style={{ width: "12%" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("username");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Username
                                </Link>
                              </th>
                              <th scope="col" style={{ width: "13%" }}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOrderBy("created");
                                    setOrderAsc(!orderAsc);
                                    setLoadTable(true);
                                    setLoading(true);
                                  }}
                                  className="text-warning"
                                >
                                  Criado
                                </Link>
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Créditos
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Status
                              </th>
                              <th scope="col" style={{ width: "21%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {revendedores.length !== 0 &&
                              revendedores.map((revendedor, index) => {
                                const status = tratarStatus(revendedor);
                                const caminho = trataCaminho(
                                  userDados.data.username,
                                  revendedor.caminho
                                );
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={revendedor.id}
                                          id={`cardtableCheck-${index}`}
                                          onChange={(e) =>
                                            handleSelectSingle(e, revendedor.id)
                                          }
                                          checked={selecionados.includes(
                                            revendedor.id
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cardtableCheck01"
                                        ></label>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{revendedor.nome}</b>
                                      </p>
                                      {caminho !== "" && (
                                        <small className="text-muted text-center">
                                          {caminho}
                                        </small>
                                      )}
                                      {revendedor.email &&
                                        revendedor.email !== "" && (
                                          <small className="text-muted">
                                            <br />
                                            <b>E-mail:</b> {revendedor.email}
                                          </small>
                                        )}

                                      {revendedor.telefone &&
                                        revendedor.telefone !==
                                          "(__) _____-____" &&
                                        revendedor.telefone !== "" && (
                                          <small className="text-muted">
                                            <br />
                                            <b>Telefone:</b>{" "}
                                            {revendedor.telefone}
                                          </small>
                                        )}
                                    </td>
                                    {verRelatorio && (
                                      <td>
                                        <small className="text-success">
                                          <b>Revendedores:</b>{" "}
                                          {revendedor.revendedores_ativos}
                                        </small>
                                        <small className="text-info">
                                          <br />
                                          <b>Clientes P2P:</b>{" "}
                                          {revendedor.clientes_ativos_p2p}
                                        </small>
                                        <small className="text-secondary">
                                          <br />
                                          <b>Clientes IPTV:</b>{" "}
                                          {revendedor.clientes_ativos_iptv}
                                        </small>
                                        <small className="text-danger">
                                          <br />
                                          <b>Clientes Esgotados:</b>{" "}
                                          {revendedor.clientes_esgotados}
                                        </small>
                                      </td>
                                    )}
                                    <td>{revendedor.username || ""}</td>
                                    <td>{converterData(revendedor.created)}</td>
                                    <td>{revendedor.creditos}</td>
                                    <td>
                                      <span
                                        className={`badge bg-${
                                          status === "Ativo"
                                            ? "success"
                                            : status === "Esgotado"
                                            ? "danger"
                                            : status === "Bloqueado"
                                            ? "info"
                                            : "dark"
                                        }`}
                                      >
                                        {status}
                                      </span>
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Enviar Ticket de Suporte"
                                        onClick={() =>
                                          toggleModalCompose(revendedor)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="mail"
                                          width="16"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to={`/clientes/${revendedor.id}`}
                                        className="text-primary"
                                        title="Ver Clientes"
                                      >
                                        <FeatherIcon
                                          icon="users"
                                          width="16"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to={`/revendedores/${revendedor.id}`}
                                        className="text-warning"
                                        title="Ver Revendedores"
                                        onClick={() => {
                                          setPaginaAtual(1);
                                          setTermoBusca("");
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="users"
                                          width="16"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-secondary"
                                        title="Editar"
                                        onClick={() =>
                                          toggleModalEditar(revendedor)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="16"
                                          className="me-3"
                                        />
                                      </Link>
                                      {userDados?.data?.id === 1 && (
                                        <Link
                                          to="#"
                                          className="text-primary"
                                          title="Mudar Master"
                                          onClick={() =>
                                            toggleModalMudarMaster(revendedor)
                                          }
                                        >
                                          <FeatherIcon
                                            icon="user"
                                            width="16"
                                            className="me-3"
                                          />
                                        </Link>
                                      )}
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Adicionar Créditos"
                                        onClick={() =>
                                          toggleModalAdicionarCreditos(
                                            revendedor
                                          )
                                        }
                                      >
                                        <FeatherIcon
                                          icon="plus-circle"
                                          width="16"
                                          className="me-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        title="Remover Créditos"
                                        onClick={() =>
                                          toggleModalRemoverCreditos(revendedor)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="minus-circle"
                                          width="16"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-warning"
                                        title="Mudar Senha"
                                        onClick={() =>
                                          toggleModalMudarSenha(revendedor)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="key"
                                          width="16"
                                          className="ms-3"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        className={
                                          status === "Bloqueado"
                                            ? "text-danger"
                                            : "text-info"
                                        }
                                        title={
                                          status === "Bloqueado"
                                            ? "Desbloquear"
                                            : "Bloquear"
                                        }
                                        onClick={() =>
                                          toggleModalBloquear(revendedor)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="lock"
                                          width="16"
                                          className="ms-3"
                                        />
                                      </Link>

                                      <Link
                                        to={`/logs/${revendedor.id}`}
                                        className="text-warning"
                                        title="Logs do Revendedor"
                                      >
                                        <FeatherIcon
                                          icon="file"
                                          width="16"
                                          className="ms-3"
                                        />
                                      </Link>
                                      {status !== "Lixeira" && (
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          title="Apagar"
                                          onClick={() =>
                                            toggleModalApagar(revendedor)
                                          }
                                        >
                                          <FeatherIcon
                                            icon="trash"
                                            width="16"
                                            className="ms-3"
                                          />
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && revendedores.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum revendedor encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeRevendedores
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeRevendedores
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeRevendedores}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeRevendedores}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalEditar
            modalEditar={modalEditar}
            setModalEditar={setModalEditar}
            setRefresh={setRefresh}
            revendedor={revendedor}
            converterData={converterData}
          />
          {userDados?.data?.id === 1 && (
            <ModalMudarMaster
              modalMudarMaster={modalMudarMaster}
              setModalMudarMaster={setModalMudarMaster}
              setRefresh={setRefresh}
              revendedor={revendedor}
            />
          )}
          <ModalAdicionarCreditos
            modalAdicionarCreditos={modalAdicionarCreditos}
            setModalAdicionarCreditos={setModalAdicionarCreditos}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />
          <ModalAdicionarCreditosMassa
            modalAdicionarCreditosMassa={modalAdicionarCreditosMassa}
            setModalAdicionarCreditosMassa={setModalAdicionarCreditosMassa}
            setRefresh={setRefresh}
            selecionados={selecionados}
          />
          <ModalRemoverCreditos
            modalRemoverCreditos={modalRemoverCreditos}
            setModalRemoverCreditos={setModalRemoverCreditos}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />
          <ModalNovo
            modalNovo={modalNovo}
            setModalNovo={setModalNovo}
            setRefresh={setRefresh}
          />
          <ModalMudarSenha
            modalMudarSenha={modalMudarSenha}
            setModalMudarSenha={setModalMudarSenha}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />
          <ModalBloquear
            modalBloquear={modalBloquear}
            setModalBloquear={setModalBloquear}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />
          <ModalApagar
            modalApagar={modalApagar}
            setModalApagar={setModalApagar}
            setRefresh={setRefresh}
            revendedor={revendedor}
          />

          <ModalCompose
            modal={modalCompose}
            toggle={toggleModalCompose}
            setModal={setModalCompose}
            revendedores={[]}
            revendedor={revendedor}
            setRefresh={setRefresh}
            loading={loading}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Revendedores;
