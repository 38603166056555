import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();
import FeatherIcon from "feather-icons-react";

const ModalEditar = ({
  modalEditar,
  setModalEditar,
  setRefresh,
  clienteId,
  converterData,
}) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [telefone, setTelefone] = useState("");
  const [conexoes, setConexoes] = useState("1");
  const [painelTipo, setPainelTipo] = useState(1);
  const [planos, setPlanos] = useState([]);
  const [planosCliente, setPlanosCliente] = useState([]);
  const [cliente, setCliente] = useState({});
  const [observacao, setObservacao] = useState("");

  const copyWhatsAppText = async () => {
    const whatsappTextarea = document.getElementById("whatsapp");
    if (whatsappTextarea) {
      try {
        await navigator.clipboard.writeText(whatsappTextarea.value);
        toast.success("Texto copiado para a área de transferência!");
      } catch (err) {
        toast.error("Falha ao copiar texto.");
        console.error(err);
      }
    }
  };

  function filtraSenha(input) {
    const resultado = input.replace(/[^A-Za-z0-9_]/g, "");
    setSenha(resultado.slice(0, 10));
  }

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const editar = async () => {
    setLoading(true);
    const data = {
      nome,
      username,
      email,
      telefone,
      password: senha,
      conexoes: conexoes === "" ? 1 : conexoes,
      planos: planosCliente,
      observacao,
    };

    const response = await apiClient.put(`/clientes/${clienteId}`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setNome("");
      setUsername("");
      setEmail("");
      setSenha("");
      setTelefone("");
      setConexoes("1");
      setPlanosCliente([]);
      setObservacao("");
      setRefresh(true);
      setModalEditar(!modalEditar);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async (id) => {
    setLoading(true);

    const response = await apiClient.get(`/clientes/${id}`);
    if (response.sucesso) {
      const dados = response.dados;
      setNome(dados.cliente.nome);
      setUsername(dados.cliente.username);
      setSenha(dados.cliente.externo_senha);
      setEmail(dados.cliente.email);
      setTelefone(dados.cliente.telefone);
      setConexoes(dados.cliente.conexoes);
      setPlanosCliente(dados.cliente.planos);
      setPlanos(dados.planos);
      setPainelTipo(dados.cliente.painel_tipo);
      setObservacao(dados.cliente.observacao);
      setCliente(dados.cliente);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (modalEditar && clienteId != 0) {
      fetchData(clienteId);
    }
  }, [modalEditar, fetchData]);
  return (
    <Modal
      isOpen={modalEditar}
      toggle={() => {
        setModalEditar(!modalEditar);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalEditar(!modalEditar);
        }}
      >
        Editar Cliente
        <br />
        <small className="text-muted">
          Desde {converterData(cliente.created)}
        </small>
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="username" className="form-label">
                  Usuário
                </Label>
                <Input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <small className="text-muted">
                  De 8 a 15 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e)}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  defaultValue={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="senha" className="form-label">
                  Senha <span className="text-danger">*</span>
                </Label>
                <Input
                  id="senha"
                  className="form-control"
                  type="text"
                  placeholder="Escreva apenas se desejar alterar"
                  value={senha}
                  onChange={(e) => filtraSenha(e.target.value)}
                />
                <small className="text-muted">
                  Mínimo de 8 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label htmlFor="planos" className="form-label">
                Planos <span className="text-danger">*</span>
              </Label>
              <div>
                {painelTipo === 1
                  ? planos.map((plano, index) => (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([...planosCliente, plano.id]);
                            } else {
                              setPlanosCliente(
                                planosCliente.filter(
                                  (item) => item !== plano.id
                                )
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ))
                  : planos.map((plano, index) => (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoCliente"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosCliente.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosCliente([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ))}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="observacao" className="form-label mb-3">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="whatsapp" className="form-label">
                  Texto para WhatsApp
                </Label>{" "}
                <Link
                  to="#"
                  className="btn btn-link link-warning fw-medium"
                  onClick={() => copyWhatsAppText()}
                  disabled={loading}
                >
                  <FeatherIcon
                    icon="copy"
                    className="cursor-pointer"
                    size={14}
                  />{" "}
                  Copiar
                </Link>
                <Input
                  id="whatsapp"
                  className="form-control"
                  type="textarea"
                  value={`Olá! Obrigado por escolher nossos serviços. Aqui estão suas informações de acesso:\n\n**Usuário**: ${username}\n**Senha**: ${senha}\n**Tipo de acesso**: ${
                    painelTipo === "0" ? "P2P" : "IPTV"
                  }\n\nPara acessar o seu painel de cliente, vá em https://clientes.hzx.digital e use seu usuário e senha acima. Lá você encontrará os aplicativos ideais para assistir o conteúdo da HZ e um webplayer exclusivo para nossos conteúdos.\n\nAproveite uma nova maneira de assistir TV! 🎬📺`}
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => setModalEditar(!modalEditar)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar()}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
