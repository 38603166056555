import React, { useState, useCallback, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalLimparLixeira = ({ modalLimparLixeira, setModalLimparLixeira, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const apagar = async () => {
    setLoading(true);

    const response = await apiClient.delete(`/clientes/lixeira/limpar`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalLimparLixeira(!modalLimparLixeira);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalLimparLixeira}
      toggle={() => {
        setModalLimparLixeira(!modalLimparLixeira);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalLimparLixeira(!modalLimparLixeira);
        }}
      >
        Limpar Lixeira
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  Todos os clientes da sua lixeira serão <b className="text-danger">apagados</b> permanentemente e não poderão ser recuperados.
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-danger fw-medium`}
              onClick={() => setModalLimparLixeira(!modalLimparLixeira)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-danger`}
              onClick={() => apagar()}
              disabled={loading}
            >
              Apagar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalLimparLixeira;
