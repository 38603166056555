import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalUrlLista = ({ modal, toggle, loading, setLoading }) => {
  const [refresh, setRefresh] = useState(true);
  const [dominios, setDominios] = useState([]);
  const [dominio, setDominio] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get(`/url-lista`);
    if (response.sucesso) {
      setDominios(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setRefresh(false);
    setLoading(false);
  }, []);

  const criar = async () => {
    setLoading(true);

    let url = dominio.trim();
    if (!url.match(/^(http|https):\/\//)) {
      url = "http://" + url;
    }

    //verifica se o último caractere é uma barra. Se não for, adiciona
    if (url.charAt(url.length - 1) !== "/") {
      url = url + "/";
    }

    const data = {
      dominio: url,
    };

    const response = await apiClient.post("/url-lista", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setDominio("");
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const apagar = async (dominioId) => {
    setLoading(true);

    const response = await apiClient.delete(`/url-lista/${dominioId}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const executar = async () => {
      await fetchData();
    };
    if (modal) executar();
  }, [modal, refresh]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Gerenciar URLs de Listas
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div>
          <Row className="mt-3">
            <Col md="12">
              <div className="form-group">
                <Label for="dominio">Cadastrar URL</Label>
                <Input
                  type="text"
                  id="dominio"
                  placeholder="Digite a url"
                  value={dominio}
                  onChange={(e) => setDominio(e.target.value)}
                />
              </div>
              <div className="hstack gap-2 justify-content-end mt-2">
                <button
                  className="btn btn-success btn-sm"
                  onClick={() => criar()}
                  disabled={loading}
                >
                  Cadastrar
                </button>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <table className="table table-sm table-borderless">
              <tbody>
                {dominios.map((item) => (
                  <tr key={item.id} className="border-bottom">
                    <td className="text-warning p-2">{item.dominio}</td>
                    <td className="text-end">
                      <button
                        className="btn btn-link btn-sm text-danger"
                        onClick={() => apagar(item.id)}
                        disabled={loading}
                      >
                        <FeatherIcon icon="trash-2" size="16" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-success fw-medium`}
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalUrlLista;
