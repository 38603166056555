import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

import { checkLastLogin } from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalApagarOld = ({ modal, toggle, loading, setLoading, setRefresh }) => {
  const [dias, setDias] = useState(20);
  const apagar = async () => {
    setLoading(true);

    const data = {
      dias,
    };

    const response = await apiClient.post(`/avisos/delete-old`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      id="composemodal"
      className="modal-lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3 bg-light" toggle={toggle}>
        Apagar Avisos Antigos
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label for="dias">Apagar avisos com mais de quantos dias?</Label>
            <Input
              type="text"
              id="dias"
              name="dias"
              value={dias}
              onChange={(e) => setDias(e.target.value.replace(/\D/g, ""))}
            />
          </Col>
        </Row>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-ghost-danger"
          onClick={() => {
            toggle();
          }}
        >
          Fechar
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            apagar();
          }}
          disabled={loading}
        >
          Apagar
        </button>
      </div>
    </Modal>
  );
};

export default ModalApagarOld;
