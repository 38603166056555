import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalMudarMaster = ({
  modalMudarMaster,
  setModalMudarMaster,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [novoMaster, setNovoMaster] = useState(0);
  const [busca, setBusca] = useState("");
  const [masters, setMasters] = useState([]);
  const [userDados, setUserDados] = useState({});

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const pesquisar = async () => {
    if (busca == "") return;
    setLoading(true);
    const userData = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(userData);

    const response = await apiClient.post("/revendedores/busca", {
      status: "Ativo",
      itens_pagina: 100,
      pagina: 1,
      termo: busca,
      base: "todos",
    });

    if (response.sucesso) {
      const dados = response.dados;
      setMasters(dados.data);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const mudar = async (revendedor) => {
    setLoading(true);

    const response = await apiClient.put(
      `/revendedores/${revendedor.id}/mudar-master/${novoMaster}`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setNovoMaster(0);
      setModalMudarMaster(!modalMudarMaster);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalMudarMaster}
      toggle={() => {
        setModalMudarMaster(!modalMudarMaster);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalMudarMaster(!modalMudarMaster);
        }}
      >
        Mudar Master de {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row>
            <div>
              <Label htmlFor="novoMaster" className="form-label">
                Pesquisar Novo Master
              </Label>
              <Input
                type="text"
                className="form-control"
                id="novoMaster"
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                maxLength={80}
              />{" "}
              <div className="d-flex justify-content-end text-right">
                <button
                  className="btn btn-sm btn-primary mt-3"
                  onClick={() => pesquisar()}
                  disabled={loading}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </Row>

          <Row className="mt-2">
            <Label check>
              <Input
                type="radio"
                name="novoMaster"
                value={1}
                onChange={(e) => setNovoMaster(e.target.value)}
              />{" "}
              hzGerente
            </Label>
          </Row>

          {loading ? (
            <Row className="mt-2 text-center d-flex justify-content-center">
              <Spinner />
            </Row>
          ) : (
            masters.length > 0 &&
            masters.map((master) => (
              <Row key={master.id} className="mt-2">
                <Label check>
                  <Input
                    type="radio"
                    name="novoMaster"
                    value={master.id}
                    onChange={(e) => setNovoMaster(e.target.value)}
                  />{" "}
                  {master.username}
                </Label>
              </Row>
            ))
          )}

          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => setModalMudarMaster(!modalMudarMaster)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => mudar(revendedor)}
              disabled={loading}
            >
              Mudar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMudarMaster;
