import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  checkLastLogin,
  generateRandomString,
} from "../../Components/FunctionsRepository";
import FeatherIcon from "feather-icons-react";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovo = ({ modalNovo, setModalNovo, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [telefone, setTelefone] = useState("");
  const [horas, setHoras] = useState("2");
  const [painelTipo, setPainelTipo] = useState("0");
  const [planos, setPlanos] = useState([]);
  const [planosTeste, setPlanosTeste] = useState([]);
  const [observacao, setObservacao] = useState("");

  const copyWhatsAppText = async () => {
    const whatsappTextarea = document.getElementById("whatsapp");
    if (whatsappTextarea) {
      try {
        await navigator.clipboard.writeText(whatsappTextarea.value);
        toast.success("Texto copiado para a área de transferência!");
      } catch (err) {
        toast.error("Falha ao copiar texto.");
        console.error(err);
      }
    }
  };

  function filtraSenha(input) {
    const resultado = input.replace(/[^A-Za-z0-9_]/g, "");
    setSenha(resultado.slice(0, 10));
  }

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  function filtraUsername(input) {
    const resultado = input.replace(/[^A-Za-z0-9_]/g, "");
    setUsername(resultado.slice(0, 15));
  }

  const criar = async () => {
    setLoading(true);

    const response = await apiClient.post(`/testes`, {
      nome,
      username,
      password: senha,
      email,
      telefone,
      horas,
      painel_tipo: parseInt(painelTipo),
      planos: planosTeste.map((plano) => parseInt(plano)),
      observacao,
    });

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      setNome("");
      setUsername("");
      setEmail("");
      setSenha("");
      setTelefone("");
      setHoras("2");
      setPainelTipo("0");
      setPlanosTeste([]);
      setObservacao("");

      setModalNovo(!modalNovo);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const response = await apiClient.get("/planos");
    if (response.sucesso) {
      setPlanos(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setUsername(generateRandomString());
    setSenha(generateRandomString());

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchData();
    };
    if (modalNovo) {
      fetch();
    }
  }, [modalNovo, fetchData]);
  return (
    <Modal
      isOpen={modalNovo}
      toggle={() => {
        setModalNovo(!modalNovo);
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalNovo(!modalNovo);
        }}
      >
        Novo Teste
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nome <span className="text-danger">*</span>
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="username" className="form-label">
                  Usuário <span className="text-danger">*</span>
                </Label>
                <Input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => filtraUsername(e.target.value)}
                />
                <small className="text-muted">
                  De 8 a 15 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="email" className="form-label">
                  E-mail
                </Label>
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => formatEmail(e)}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="telefone" className="form-label">
                  Telefone
                </Label>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="(11) 99999-9999"
                  defaultValue={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="senha" className="form-label">
                  Senha <span className="text-danger">*</span>
                </Label>
                <Input
                  id="senha"
                  className="form-control"
                  type="text"
                  placeholder="Digite a Senha"
                  value={senha}
                  onChange={(e) => filtraSenha(e.target.value)}
                />
                <small className="text-muted">
                  Mínimo de 8 caracteres, apenas letras e números
                </small>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="horas" className="form-label">
                  Horas <span className="text-danger">*</span>
                </Label>
                <Input
                  id="horas"
                  className="form-control"
                  type="select"
                  value={horas}
                  onChange={(e) => setHoras(e.target.value)}
                >
                  <option value="2">2</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="painelTipo" className="form-label">
                  Painel Tipo <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  id="painelTipo"
                  className="form-select"
                  value={painelTipo}
                  onChange={(e) => {
                    setPainelTipo(e.target.value);
                    setPlanosTeste([]);
                  }}
                >
                  <option value="0">P2P</option>
                  <option value="1">IPTV</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label htmlFor="planos" className="form-label">
                Planos <span className="text-danger">*</span>
              </Label>
              <div>
                {planos.map((plano, index) => {
                  if (plano.painel_tipo === parseInt(painelTipo)) {
                    return parseInt(painelTipo) === 1 ? (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosTeste.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosTeste([...planosTeste, plano.id]);
                            } else {
                              setPlanosTeste(
                                planosTeste.filter((item) => item !== plano.id)
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    ) : (
                      <Col
                        md="12"
                        key={index}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="planoTeste"
                          id={`plano-${plano.id}`}
                          value={plano.id}
                          checked={planosTeste.includes(plano.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPlanosTeste([plano.id]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`plano-${plano.id}`}
                        >
                          {plano.nome_plano}
                        </label>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="observacao" className="form-label mb-3">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="whatsapp" className="form-label">
                  Texto para WhatsApp
                </Label>{" "}
                <Link
                  to="#"
                  className="btn btn-link link-warning fw-medium"
                  onClick={() => copyWhatsAppText()}
                  disabled={loading}
                >
                  <FeatherIcon
                    icon="copy"
                    className="cursor-pointer"
                    size={14}
                  />{" "}
                  Copiar
                </Link>
                <Input
                  id="whatsapp"
                  className="form-control"
                  type="textarea"
                  value={`Olá! Obrigado por escolher nossos serviços. Aqui estão suas informações de acesso:\n\n**Usuário**: ${username}\n**Senha**: ${senha}\n**Tipo de acesso**: ${
                    painelTipo === "0" ? "P2P" : "IPTV"
                  }\n\nPara baixar os nossos aplicativos, vá em https://loja.hzplay.fun e use seu usuário e senha acima.\n\nAproveite uma nova maneira de assistir TV! 🎬📺`}
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => setModalNovo(!modalNovo)}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
