import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../ParticlesAuth";

// Import Images
import error from "../../../assets/images/spider.png";

const Basic404 = () => {
  document.title =
    "404 Error Basic | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center pt-4">
                    <div className="">
                      <img
                        src={error}
                        alt=""
                        height={250}
                        className="error-basic-img move-animation"
                      />
                    </div>
                    <div className="mt-n4">
                      <h1 className="display-1 fw-medium">404</h1>
                      <h3 className="text-uppercase text-warning">
                        Página não encontrada!
                      </h3>
                      <p className="mb-2" style={{fontSize:"1rem"}}>
                        Eita, parece que você entrou em uma área restrita do
                        portal! A página que você está procurando não existe ou
                        está em um bunker secreto protegido por ex-agentes da
                        KGB.{" "}
                      </p>
                      <p className="text-muted mb-2" style={{fontSize:"1rem"}}>
                        Ou quem sabe você simplesmente digitou o endereço errado
                        enquanto tentava acessar o site da sua tia para ver as
                        fotos dela fazendo ioga na praia? (Não julgamos...)
                      </p>
                      <p className="mb-2" style={{fontSize:"1rem"}}>De qualquer forma, a
                        menos que você seja um hacker de elite ou um agente
                        duplo disfarçado, sugiro que dê meia-volta e volte para
                        os caminhos iluminados do nosso maravilhoso painel. Mas fique à vontade para dar uma nova tentativa quando
                        souber para onde quer ir.{" "}
                      </p><br />
                      <p className="text-warning mb-4" style={{fontSize:"1.1rem"}}>
                        {" "}
                        Um abraço do seu amigo, Erro 404
                      </p>
                      <Link to="/principal" className="btn btn-success">
                        <i className="mdi mdi-home me-1"></i>Volte ao seu painel
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default Basic404;
