import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalRelatorio = ({ modal, toggle, loading, setLoading }) => {
  const [financeiro, setFinanceiro] = useState(false);
  const [tipo, setTipo] = useState("pdf");
  const [detalhado, setDetalhado] = useState(false);

  const baixarRelatorio = async () => {
    setLoading(true);

    const data = {
      financeiro,
      detalhado,
    };

    try {
      const endpoint = tipo === "pdf" ? "/relatorios/pdf" : "/relatorios/csv";
      const response = await apiClient.postDownload(endpoint, data);
      
      if (response.sucesso && response.dados) {
        const blob = new Blob([response.dados], { type: tipo === "pdf" ? 'application/pdf' : 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        
        let filename = `relatorio_${detalhado ? 'detalhado' : 'simples'}_${financeiro ? 'financeiro_' : ''}${new Date().toISOString().split('T')[0]}.${tipo}`;
        
        // Check if the server provided a filename
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }
        
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        
        toast.success(`Relatório ${tipo.toUpperCase()} gerado com sucesso!`);
      } else {
        toast.error(response.mensagem || "Falha ao gerar relatório");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.mensagem || "Erro ao gerar relatório");
      if (error.responseCode === 401) {
        await checkLastLogin();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Download de Relatórios
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div>
          <Row className="mt-3">
            <Col>
              <Label className="form-label">Tipo de Relatório</Label>
              <div>
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="pdfRadio"
                    name="tipoRelatorio"
                    value="pdf"
                    checked={tipo === "pdf"}
                    onChange={() => setTipo("pdf")}
                  />
                  <Label className="form-check-label" htmlFor="pdfRadio">
                    PDF
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="csvRadio"
                    name="tipoRelatorio"
                    value="csv"
                    checked={tipo === "csv"}
                    onChange={() => setTipo("csv")}
                  />
                  <Label className="form-check-label" htmlFor="csvRadio">
                    CSV
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label className="form-label">Nível de Detalhamento</Label>
              <div>
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="simplesRadio"
                    name="nivelDetalhamento"
                    value="simples"
                    checked={!detalhado}
                    onChange={() => setDetalhado(false)}
                  />
                  <Label className="form-check-label" htmlFor="simplesRadio">
                    Simples
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="detalhadoRadio"
                    name="nivelDetalhamento"
                    value="detalhado"
                    checked={detalhado}
                    onChange={() => setDetalhado(true)}
                  />
                  <Label className="form-check-label" htmlFor="detalhadoRadio">
                    Detalhado
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-check">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="financeiroCheck"
                  checked={financeiro}
                  onChange={() => setFinanceiro(!financeiro)}
                />
                <Label className="form-check-label" htmlFor="financeiroCheck">
                  Incluir dados financeiros
                </Label>
              </div>
              <small className="text-muted">
                Nota: Os dados financeiros só serão precisos se os revendedores tiverem o campo "Valor Cobrado" corretamente preenchido.
              </small>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                color="primary"
                onClick={baixarRelatorio}
                disabled={loading}
              >
                {loading ? "Gerando..." : `Baixar Relatório ${tipo.toUpperCase()}`}
              </Button>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalRelatorio;
