import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalMudarRevendedor = ({
  modalMudarRevendedor,
  setModalMudarRevendedor,
  setRefresh,
  cliente,
}) => {
  const [loading, setLoading] = useState(false);
  const [novoRevenda, setNovoRevenda] = useState(0);
  const [busca, setBusca] = useState("");
  const [revendas, setRevendas] = useState([]);
  const [userDados, setUserDados] = useState({});

  const toggle = () => {
    setBusca("");
    setModalMudarRevendedor(!modalMudarRevendedor);
  };

  const pesquisar = async () => {
    setLoading(true);
    const userData = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(userData);

    const response = await apiClient.post("/revendedores/busca", {
      status: "Ativo",
      itens_pagina: 15,
      pagina: 1,
      termo: busca,
      base: "todos",
    });

    if (response.sucesso) {
      const dados = response.dados;
      setRevendas(dados.data);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const mudar = async (cliente_id) => {
    setLoading(true);

    const response = await apiClient.put(
      `/clientes/${cliente_id}/mudar-revenda/${novoRevenda}`,
      {}
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setNovoRevenda(0);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
    await pesquisar();
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    if (modalMudarRevendedor) fetchData();
  }, [modalMudarRevendedor]);

  return (
    <Modal
      isOpen={modalMudarRevendedor}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Mudar Revenda de {cliente?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row>
            <div>
              <Label htmlFor="novoRevenda" className="form-label">
                Pesquisar Novo Revenda
              </Label>
              <Input
                type="text"
                className="form-control"
                id="novoRevenda"
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                maxLength={80}
              />{" "}
              <div className="d-flex justify-content-end text-right">
                <button
                  className="btn btn-sm btn-success mt-3"
                  onClick={() => pesquisar()}
                  disabled={loading}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </Row>

          {userDados?.data?.id === 1 && (
            <Row className="mt-2">
              <Label check>
                <Input
                  type="radio"
                  name="novoMaster"
                  value={1}
                  onChange={(e) => setNovoMaster(e.target.value)}
                />{" "}
                hzGerente
              </Label>
            </Row>
          )}

          {loading ? (
            <Row className="mt-2 text-center d-flex justify-content-center">
              <Spinner />
            </Row>
          ) : (
            revendas.length > 0 &&
            revendas.map((revenda) => (
              <Row key={revenda.id} className="mt-2">
                <Label check>
                  <Input
                    type="radio"
                    name="novoRevenda"
                    value={revenda.id}
                    onChange={(e) => setNovoRevenda(e.target.value)}
                  />{" "}
                  {revenda.username}
                </Label>
              </Row>
            ))
          )}

          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => mudar(cliente?.id)}
              disabled={loading}
            >
              Mudar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalMudarRevendedor;
