import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalRemoverCreditos = ({
  modalRemoverCreditos,
  setModalRemoverCreditos,
  setRefresh,
  revendedor,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [creditosRemover, setCreditosRemover] = useState(0);
  const [userDados, setUserDados] = useState({});

  const getUserDados = async () => {
    const dados = JSON.parse(localStorage.getItem("authUser"));
    setUserDados(dados.data);
  };

  const remover = async () => {
    setLoading(true);

    const data = {
      creditos: creditosRemover === "" ? 0 : parseInt(creditosRemover),
      motivo,
    };

    const response = await apiClient.post(
      `/revendedores/removerCreditos/${revendedor.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem || "Créditos removidos com sucesso.");
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setCreditosRemover("0");
      setMotivo("");
      setModalRemoverCreditos(!modalRemoverCreditos);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserDados();
    };
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalRemoverCreditos}
      toggle={() => {
        setModalRemoverCreditos(!modalRemoverCreditos);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalRemoverCreditos(!modalRemoverCreditos);
        }}
      >
        Remover Créditos de {revendedor?.nome}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {!loading && (
          <div className="mt-4">
            <Row className="mt-3">
              <div>
                <Label htmlFor="creditos" className="form-label">
                  Créditos a remover (Atual: {revendedor?.creditos})
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="creditos"
                  value={creditosRemover}
                  onChange={(e) =>
                    setCreditosRemover(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={8}
                />
              </div>
            </Row>

            {userDados?.id !== 1 && (
              <Row className="mt-3">
                <div>
                  <Label htmlFor="motivo" className="form-label">
                    Motivo
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="motivo"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    maxLength={255}
                  />
                  <small className="text-muted">
                    Este formulário envia um pedido para remover os créditos. O
                    pedido será analisado e, se aprovado, os créditos serão
                    removidos.
                  </small>
                </div>
              </Row>
            )}
            <div className="hstack gap-2 justify-content-center mt-5">
              <Link
                to="#"
                className="btn btn-link link-danger fw-medium"
                onClick={() => setModalRemoverCreditos(!modalRemoverCreditos)}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link to="#" className="btn btn-danger" onClick={() => remover()}>
                Remover
              </Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalRemoverCreditos;
