import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import SearchOption from "./SearchOption";
import ModalVer from "./ModalVer";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const Solicitacoes = () => {
  document.title = "Solicitacoes | Dark Store";
  const [modalVer, setModalVer] = useState(false);

  const [solicitacao, setSolicitacao] = useState(null);
  const [solicitacaoDados, setSolicitacaoDados] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [userDados, setUserDados] = useState(null);

  const [solicitacoes, setSolicitacoes] = useState([]);

  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [totalDeSolicitacoes, setTotalDeSolicitacoes] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");
  const [status, setStatus] = useState("Pendente");

  const [selecionados, setSelecionados] = useState([]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const todosIds = solicitacoes.map((solicitacao) => solicitacao.id);
      setSelecionados(todosIds);
    } else {
      setSelecionados([]);
    }
  };

  const handleSelectSingle = (e, id) => {
    if (e.target.checked) {
      setSelecionados((prevSelecionados) => [...prevSelecionados, id]);
    } else {
      setSelecionados((prevSelecionados) =>
        prevSelecionados.filter((sid) => sid !== id)
      );
    }
  };

  const tratarStatus = (solicitacao) => {
    if (solicitacao.aprovado === 1) {
      return "Aprovado";
    }
    if (solicitacao.aprovado === -1) {
      return "Reprovado";
    }
    return "Pendente";
  };

  const toggleModalVer = useCallback(async (cli, data) => {
    setSolicitacao(cli);
    setSolicitacaoDados(data);
    setModalVer(true);
  }, []);

  const calcularPaginas = () => {
    const paginas = [];
    const paginasVisiveis = 2;

    // Adicionar as três primeiras páginas
    for (let i = 1; i <= Math.min(paginasVisiveis, numeroDePaginas); i++) {
      paginas.push(i);
    }

    let inicio = Math.max(paginaAtual - paginasVisiveis, paginasVisiveis + 1);
    let fim = Math.min(
      paginaAtual + paginasVisiveis,
      numeroDePaginas - paginasVisiveis
    );

    if (inicio > paginas[paginas.length - 1] + 1) {
      paginas.push("...");
    }

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    if (fim < numeroDePaginas - paginasVisiveis) {
      paginas.push("...");
    }

    // Adicionar as três últimas páginas
    for (
      let i = Math.max(
        numeroDePaginas - paginasVisiveis + 1,
        paginasVisiveis + 1
      );
      i <= numeroDePaginas;
      i++
    ) {
      if (!paginas.includes(i)) {
        paginas.push(i);
      }
    }

    return paginas;
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchSolicitacoes = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca, status) => {
      if (!userDados) {
        const userData = await apiClient.getLoggedinUser();
        setUserDados(userData);
        if (userData?.data?.id !== 1) {
          status = "Todos";
          setStatus(status);
          console.log(status);
        }
      }
      const data = {
        itens_pagina: itensPorPagina,
        pagina: termoBusca === "" ? paginaAtual : 1,
        termo: termoBusca,
        status,
      };

      const response = await apiClient.post("/solicitacao/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setSolicitacoes(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeSolicitacoes(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchSolicitacoes(itensPorPagina, paginaAtual, termoBusca, status);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
    };

    fetchData();
    setLoadTable(false);
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, status, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Solicitacoes" pageTitle="Grupos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={8}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={status}
                >
                  <option value="Todos">Todos</option>
                  <option value="Aprovado">Aprovado</option>
                  <option value="Reprovado">Reprovado</option>
                  <option value="Pendente">Pendente</option>
                </select>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col xl={12}>
              <small className="text-muted text-left mb-2">
                {selecionados.length} solicitações selecionadas
              </small>
            </Col>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Suas Solicitações
                  </h4>
                </CardHeader>
                <CardBody>
                  {!loading &&
                  !loadTable &&
                  solicitacoes.length === 0 ? null : (
                    <div className="align-items-center mt-2 mb-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeSolicitacoes
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeSolicitacoes
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeSolicitacoes}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "46px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="cardtableCheck"
                                    onChange={(e) => handleSelectAll(e)}
                                    checked={
                                      selecionados.length ===
                                      solicitacoes.length
                                    }
                                    ref={(el) =>
                                      el &&
                                      (el.indeterminate =
                                        selecionados.length > 0 &&
                                        selecionados.length <
                                          solicitacoes.length)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="cardtableCheck"
                                  ></label>
                                </div>
                              </th>
                              <th scope="col" style={{ width: "22%" }}>
                                Pedido
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Solicitante
                              </th>
                              <th scope="col" style={{ width: "40%" }}>
                                Motivo
                              </th>
                              <th scope="col" style={{ width: "11%" }}>
                                Status
                              </th>
                              <th scope="col" style={{ width: "15%" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {solicitacoes.length !== 0 &&
                              solicitacoes.map((solicitacao, index) => {
                                const solicitacaoDados = JSON.parse(
                                  solicitacao?.data
                                );

                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={solicitacao}
                                          id={`cardtableCheck-${index}`}
                                          onChange={(e) =>
                                            handleSelectSingle(e, solicitacao)
                                          }
                                          checked={selecionados.includes(
                                            solicitacao
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="cardtableCheck01"
                                        ></label>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>
                                          {solicitacaoDados?.tipo ===
                                          "mudar_senha"
                                            ? `Mudar senha de ${solicitacaoDados?.username}`
                                            : solicitacaoDados?.tipo ===
                                              "deletar_revendedor"
                                            ? `Deletar revendedor ${solicitacaoDados?.username}`
                                            : solicitacaoDados?.tipo ===
                                              "bloquear_revendedor"
                                            ? `Bloquear revendedor ${solicitacaoDados?.username}`
                                            : solicitacaoDados?.tipo ===
                                              "remover_credito"
                                            ? `Remover ${solicitacaoDados?.creditos} Crédito(s) de ${solicitacaoDados?.username}`
                                            : "Desconhecido"}
                                        </b>
                                      </p>
                                      <small className="text-muted">
                                        Feito em{" "}
                                        {converterData(solicitacao.created_at)}
                                      </small>
                                    </td>
                                    <td>{solicitacao?.user?.username || ""}</td>
                                    <td>
                                      {solicitacao?.motivo?.substring(0, 50)}
                                      {solicitacao?.motivo?.length > 50
                                        ? "..."
                                        : ""}
                                    </td>

                                    <td>
                                      {solicitacao?.aprovado === 1 ? (
                                        <Badge color="success">Aprovado</Badge>
                                      ) : solicitacao?.aprovado === -1 ? (
                                        <Badge color="danger">Reprovado</Badge>
                                      ) : (
                                        <Badge color="secondary">
                                          Pendente
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Ver Solicitação"
                                        onClick={() => {
                                          toggleModalVer(
                                            solicitacao,
                                            solicitacaoDados
                                          );
                                        }}
                                      >
                                        <FeatherIcon icon="eye" width="18" />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && solicitacoes.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhuma solicitação encontrada
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeSolicitacoes
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeSolicitacoes
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeSolicitacoes}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalVer
            modalVer={modalVer}
            setModalVer={setModalVer}
            setRefresh={setRefresh}
            solicitacao={solicitacao}
            solicitacaoDados={solicitacaoDados}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Solicitacoes;
