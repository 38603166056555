import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalRecriar = ({
  modalRecriar,
  setModalRecriar,
  setRefresh,
  cliente,
}) => {
  const [loading, setLoading] = useState(false);

  const recriar = async (clienteId) => {
    setLoading(true);

    const response = await apiClient.put(`/clientes/${clienteId}/recriar`, {});

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      setModalRecriar(!modalRecriar);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401")) {
        if (response.mensagem !== "") toast.error(response.mensagem);
      } else {
        await checkLastLogin();
      }
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modalRecriar}
      toggle={() => {
        setModalRecriar(!modalRecriar);
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setModalRecriar(!modalRecriar);
        }}
      >
        Recriar {cliente?.username}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <h3>Tem certeza disso?</h3>
                <p id="mensagem">
                  O cliente atual será{" "}
                  <b className={`text-warning`}>recriado</b> no painel original.
                </p>
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className={`btn btn-link link-warning fw-medium`}
              onClick={() => setModalRecriar(!modalRecriar)}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className={`btn btn-warning`}
              onClick={() => recriar(cliente.id)}
              disabled={loading}
            >
              Recriar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalRecriar;
